import React from 'react';
import { Dropdown, DropdownItem, Paragraph, Separator } from '@gourban/ui-components';
import UserAvatar from '@/core/components/UserAvatar';
import {
  businessAccountCreateRoot,
  businessAccountTrips,
} from '@/core/pages/BusinessAccounts/routes';
import { Trans } from '@lingui/react/macro';
import { myTrips } from '@/core/pages/MyTrips/routes';
import { accountEditBasicInfo } from '@/core/pages/Account/routes';
import useRequestUserSignOut from '@/features/auth/hooks/useRequestUserSignOut';
import styles from '@/core/assets/scss/components/UI/UserAvatarDropdown.module.scss';
import {
  useRequestBusinessAccountMembershipQuery,
  useRequestBusinessAccountQuery,
  useRequestJoinBusinessAccountRequestsQuery,
} from '@/features/businessAccounts/services/BusinessAccount.service';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { TenantAccountSettings } from '@/features/geomap/types';
import { SettingsKeys } from '@/core/enums';
import { useNavigate } from 'react-router';
import {
  useRequestBranchTermsAndConditionsQuery,
  useRequestResolvedBranchQuery,
} from '@/features/branches/services/Branches.service';
import Link from '@/core/components/UI/Link';

const UserAvatarDropdown = () => {
  const { data: businessAccountOwner } = useRequestBusinessAccountQuery();
  const { data: businessAccountMembership } = useRequestBusinessAccountMembershipQuery();
  const { data: businessAccountRequestApplications } = useRequestJoinBusinessAccountRequestsQuery();
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const { data: branchTerms } = useRequestBranchTermsAndConditionsQuery(resolvedBranch?.id!);

  const tenantAccount = useTypedSelector((state) =>
    getSetting<TenantAccountSettings>(state, SettingsKeys.TENANT_ACCOUNTS),
  );
  const onSignOutClick = useRequestUserSignOut();
  const navigate = useNavigate();

  // Users can only create business accounts if they don't belong to any ( member or admin )
  // or if they have a pending request to join a business account
  const belongsToAnyBusinessAccount =
    !!businessAccountMembership?.length ||
    !!businessAccountRequestApplications?.length ||
    businessAccountOwner;

  const isBusinessAccountActive = tenantAccount?.businessAccounts?.active;

  return (
    <Dropdown noPadding direction="bottom" align="end" variation="link" label={<UserAvatar />}>
      {!belongsToAnyBusinessAccount && isBusinessAccountActive && (
        <DropdownItem onClick={() => navigate(businessAccountCreateRoot.path)}>
          <Paragraph size={4} marginBottom={0}>
            <Trans id="header.dropdown.business">Business account</Trans>
          </Paragraph>
        </DropdownItem>
      )}
      {businessAccountOwner && isBusinessAccountActive && (
        <DropdownItem onClick={() => navigate(businessAccountTrips.path)}>
          <Paragraph size={4} marginBottom={0}>
            <Trans id="header.dropdown.businessAccount">Business account</Trans>
          </Paragraph>
        </DropdownItem>
      )}
      <DropdownItem onClick={() => navigate(myTrips.path)}>
        <Paragraph size={4} marginBottom={0}>
          <Trans id="header.dropdown.myTrips">My trips</Trans>
        </Paragraph>
      </DropdownItem>
      <DropdownItem onClick={() => navigate(accountEditBasicInfo.path)}>
        <Paragraph size={4} marginBottom={0}>
          <Trans id="header.dropdown.account">Account</Trans>
        </Paragraph>
      </DropdownItem>
      <DropdownItem onClick={onSignOutClick}>
        <Paragraph size={4} marginBottom={0}>
          <Trans id="header.dropdown.logout">Logout</Trans>
        </Paragraph>
      </DropdownItem>
      <Separator gapTop={0} gapBottom={0} />
      <DropdownItem className={styles['user-avatar-dropdown__links']}>
        <Paragraph size={5} textColor="text-400" marginBottom={0}>
          <Link external to={branchTerms?.termsAndConditions?.termsAndConditionsUrl!}>
            <Trans id="components.userAvatarDropdown.termsAndConditions">Terms & conditions</Trans>
          </Link>{' '}
          •{' '}
          <Link external to={branchTerms?.termsAndConditions?.privacyGuidelinesUrl!}>
            <Trans id="components.userAvatarDropdown.privacyPolicy">Privacy</Trans>
          </Link>
        </Paragraph>
      </DropdownItem>
    </Dropdown>
  );
};

export default UserAvatarDropdown;
