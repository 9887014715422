import { useState } from 'react';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import ResendEmailButton from '@/features/auth/components/ResendEmailButton';
import { Button, Paragraph } from '@gourban/ui-components';
import EmailLoginForm from '@/features/auth/components/EmailLoginForm';
import { Link } from 'react-router';
import { register } from '@/core/pages/Auth/routes';
import AuthenticationLayout from '@/features/auth/components/AuthenticationLayout';

const EmailAuthentication = () => {
  const [unverifiedEmail, setUnverifiedEmail] = useState<string | null>(null);
  return (
    <AuthenticationLayout
      title={
        unverifiedEmail ? (
          <Trans id="auth.loginForm.notVerfied">Your user account is not verified</Trans>
        ) : (
          <Trans id="auth.loginForm.pageTitle">Sign in</Trans>
        )
      }
      form={
        unverifiedEmail ? (
          <ResendEmailButton
            email={unverifiedEmail}
            message={t({
              id: 'auth.loginForm.unverifiedEmailInfo',
              message:
                'Your user account is not yet verified by email. If you did not receive an email after registration, click the button to send one again',
            })}
            initialTimeout={0}
          />
        ) : (
          <>
            <Paragraph weight="thin" marginBottom={0}>
              <Trans id="auth.loginForm.enterData">Please enter your data</Trans>
            </Paragraph>
            <EmailLoginForm onUnverifiedError={setUnverifiedEmail} />
          </>
        )
      }
      footer={
        unverifiedEmail ? (
          <Button variation="link" onClick={() => setUnverifiedEmail(null)}>
            <Trans id="auth.loginForm.backToLogin">Back to Login</Trans>
          </Button>
        ) : (
          <>
            <Trans id="auth.loginForm.noAccount">Don't have an account?</Trans>{' '}
            <Link to={register.path}>
              <Trans id="auth.loginForm.signUp">Sign up</Trans>
            </Link>
            .
          </>
        )
      }
      alignCenter={!!unverifiedEmail}
    />
  );
};

export default EmailAuthentication;
