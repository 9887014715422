import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';
import { Loader } from '@gourban/ui-components';

const RedirectToBusinessAccount = () => {
  const { pathname } = useLocation();
  const { isLoading: isLoadingBranches } = useRequestResolvedBranchQuery();

  if (isLoadingBranches) {
    return <Loader cover fixed />;
  }

  if (pathname === '/') {
    return <Navigate to="/business-account" />;
  }

  return <Outlet />;
};

export default RedirectToBusinessAccount;
