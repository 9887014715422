import React from 'react';
import BusinessAccountCreateLayout from '@/features/businessAccounts/components/Create/Layout';
import { Button } from '@gourban/ui-components';
import { Trans } from '@lingui/react/macro';
import { useNavigate } from 'react-router';
import CompanyInfoStep from '@/features/businessAccounts/components/Create/CompanyInfoStep';
import { Form, FormikProvider, useFormik } from 'formik';
import { BusinessAccount, BusinessAccountCreateForm } from '@/features/businessAccounts/types';
import { companyInfoSchema } from '@/features/businessAccounts/utils/validations/companyInfo.schema';
import {
  useRequestBusinessAccountQuery,
  useRequestCreateBusinessAccountMutation,
  useRequestUpdateBusinessAccountMutation,
} from '@/core/features/businessAccounts/services/BusinessAccount.service';
import useFeatureCheck from '@/core/features/auth/hooks/useFeatureCheck';
import { TenantFeatures } from '@/features/auth/enums';
import ResponsiveFixedButtons from '@/core/components/UI/ResponsiveFixedButtons';

const CompanyInfo = () => {
  const navigate = useNavigate();
  const [requestBusinessAccountCreate] = useRequestCreateBusinessAccountMutation();
  const [requestBusinessAccountUpdate] = useRequestUpdateBusinessAccountMutation();
  const { data: businessAccountData } = useRequestBusinessAccountQuery();
  const hasFeature = useFeatureCheck();

  const form = useFormik<BusinessAccountCreateForm | BusinessAccount>({
    initialValues: { ...businessAccountData } as BusinessAccount,
    validationSchema: companyInfoSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (businessAccountData) {
        await requestBusinessAccountUpdate(values as BusinessAccount).unwrap();
      } else {
        await requestBusinessAccountCreate(values as BusinessAccountCreateForm).unwrap();
      }

      navigate('/business-account/create/payment', { state: { isFromStepSwitching: true } });
    },
  });

  return (
    <FormikProvider value={form}>
      <BusinessAccountCreateLayout
        actions={
          <ResponsiveFixedButtons>
            {hasFeature(TenantFeatures.ENDUSER_WEB_APP) && (
              <Button
                disabled={form.isSubmitting}
                variation="secondary"
                attributes={{ role: 'link' }}
                onClick={() => navigate('/')}
              >
                {businessAccountData ? (
                  <Trans id="general.close">Close</Trans>
                ) : (
                  <Trans id="general.cancel">Cancel</Trans>
                )}
              </Button>
            )}
            <Button disabled={!form.isValid} loading={form.isSubmitting} onClick={form.submitForm}>
              <Trans id="general.next">Next</Trans>
            </Button>
          </ResponsiveFixedButtons>
        }
      >
        <Form>
          <CompanyInfoStep />
        </Form>
      </BusinessAccountCreateLayout>
    </FormikProvider>
  );
};

export default CompanyInfo;
