import { FC, useEffect, useState } from 'react';
import { Column, Paragraph, Button, Row } from '@gourban/ui-components';
import { ApiError } from '@/core/types';
import { useRequestResendEmailMutation } from '@/features/account/services/Account.service';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';

export interface ResendEmailButtonT {
  email: string;
  message: string;
  initialTimeout?: number;
}

const DEFAULT_TIMEOUT = 120;

const ResendEmailButton: FC<ResendEmailButtonT> = ({
  email,
  message,
  initialTimeout = DEFAULT_TIMEOUT,
}) => {
  const [resendingEmailRequest, { error, isLoading: resendingEmail }] =
    useRequestResendEmailMutation();
  const [counter, setCounter] = useState(initialTimeout);
  const counting = counter > 0;
  const buttonLabel = counting
    ? `${counter}`
    : t({
        id: 'auth.resendEmail.resend',
        message: 'Resend email',
      });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (counting) {
      const timeoutId = setInterval(() => {
        setCounter((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timeoutId);
    }
  }, [counting]);

  const resendEmail = () => {
    resendingEmailRequest({ email }).then(() => {
      setCounter(DEFAULT_TIMEOUT);
    });
  };

  return (
    <Row alignItems="center" column gapSm="sm" marginBottom={0}>
      <Column>
        <Paragraph weight="thin" marginBottom={32} align="center">
          {message}
          {counting && (
            <>
              <br />
              <br />
              <Trans id="auth.resendEmail.timer">
                To resend email please wait until the timer ends.
              </Trans>
            </>
          )}
        </Paragraph>
      </Column>
      <Column>
        <Button
          size="large"
          disabled={counting}
          onClick={resendEmail}
          variation="secondary"
          iconName="clock"
          label={buttonLabel}
          loading={resendingEmail}
        />
      </Column>
      <Column>
        {!!error && counting && (
          <Paragraph align="center" textColor="error">
            {(error as ApiError)?.userMessage}
          </Paragraph>
        )}
      </Column>
    </Row>
  );
};

export default ResendEmailButton;
