import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { GoUrbanMapInterface } from '../types';
import { Loader, Paragraph, GoUrbanMap, MapRef } from '@gourban/ui-components';
import goUrbanMap from '../assets/scss/GoMap.module.scss';
import { Trans } from '@lingui/react/macro';
import { useTypedDispatch, useTypedSelector } from '@/core/redux/hooks';
import { setUserLocationCoordinates } from '@/features/account/redux/account.reducer';
import { getUserLocationCoordinates } from '@/features/account/redux/account.selectors';

type GoMapUserLocationPermissionStates = 'uninitialized' | 'denied' | 'approved' | 'requested';

// When initially opening the app, position the map accordingly
const urlSearchParams = new URLSearchParams(window.location.search);

const [lng = 16.36008685, lat = 48.19285324] =
  urlSearchParams
    .get('c')
    ?.split(',')
    ?.map((cord) => Number(cord)) ?? [];

const zoom = Number(urlSearchParams.get('z')) || 11;

const GoMap: React.FC<PropsWithChildren<GoUrbanMapInterface>> = ({ children, onMapLoaded }) => {
  const userCoordinates = useTypedSelector(getUserLocationCoordinates);
  const mapRef = useRef<MapRef | null>(null);
  const [initialViewState, setInitialViewState] = useState({ longitude: lng, latitude: lat, zoom });
  const [userLocationPermissions, setUserLocationPermissions] =
    useState<GoMapUserLocationPermissionStates>('uninitialized');
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (userCoordinates) {
      setUserLocationPermissions('approved');

      if (!urlSearchParams.get('c')) {
        setInitialViewState({
          latitude: userCoordinates.lat,
          longitude: userCoordinates.lng,
          zoom: 14,
        });
      }
    } else if (!navigator?.geolocation) {
      setUserLocationPermissions('denied');
      dispatch(setUserLocationCoordinates({ lat, lng }));
    } else {
      setUserLocationPermissions('requested');

      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          dispatch(setUserLocationCoordinates({ lat: coords.latitude, lng: coords.longitude }));

          if (!urlSearchParams.get('c')) {
            setInitialViewState({
              latitude: coords.latitude,
              longitude: coords.longitude,
              zoom: 14,
            });
          }

          setUserLocationPermissions('approved');
        },
        () => {
          setUserLocationPermissions('denied');
          dispatch(setUserLocationCoordinates({ lat, lng }));
        },
        {
          enableHighAccuracy: false,
          timeout: 6000,
        },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setInitialViewState]);

  if (userLocationPermissions === 'uninitialized') {
    return null;
  }

  if (userLocationPermissions === 'requested') {
    return (
      <Loader fixed cover>
        <Paragraph weight="medium">
          <Trans id="geomap.goMap.waitingPermissions">Waiting for location information...</Trans>
        </Paragraph>
      </Loader>
    );
  }

  return (
    <div data-testid="goMap" className={goUrbanMap['go-map']}>
      <GoUrbanMap
        maxZoom={20}
        minZoom={9}
        ref={mapRef}
        onMapLoaded={onMapLoaded}
        initialViewState={initialViewState}
        mapStyle="mapbox://styles/mapbox/streets-v12?optimize=true"
      >
        {children}
      </GoUrbanMap>
    </div>
  );
};

export default GoMap;
