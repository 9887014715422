import { useVerificationStatus } from '@/features/account/hooks/useVerificationStatus';
import { useRequestBusinessAccountMembershipQuery } from '@/features/businessAccounts/services/BusinessAccount.service';
import { useStepStatus } from './useStepStatus';
import { useTypedSelector } from '@/core/redux/hooks';
import { getStepsStatus } from '../redux/account.selectors';

export const useAccountProfileComplete = () => {
  const verificationStatus = useVerificationStatus();
  const { data: businessAccountMember } = useRequestBusinessAccountMembershipQuery();
  const isBusinessAccountMember = !!businessAccountMember?.length;

  const stepsStatus = useStepStatus();
  const steps = useTypedSelector(getStepsStatus);

  if (stepsStatus.completed === stepsStatus.total && verificationStatus === 'successful') {
    return true;
  }

  return isBusinessAccountMember && steps['basic-info'].isComplete;
};
