import { addMinutes, format, roundToNearestMinutes, Locale, NearestMinutes } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { getMyTimezoneId } from '@/core/utils/getMyTimezoneId';

interface FormatInitialTimeArgs {
  initialTime?: string;
  buffer: number;
  roundTo?: number;
  locale?: Locale;
  timezone?: string;
}

/**
 * When displaying initial time for the date picker of booking there are several different cases that need to be considered.
 * Case 1: If start/end booking time preferences is present in the dashboard but that value had already passed -> we need to display "now" + buffer time
 * Case 2: If start/end booking time preferences is present in the dashboard and is available -> display it
 * Case 3: If start/end booking time preferences is missing in the dashboard, display now time + buffer provided
 * @param initialTime
 * @param targetDate
 * @param buffer -> How much buffer minutes should be added, eg: 30.... add 30 minutes to new Date()
 * @param roundTo
 * @param locale
 * @param timezone
 */
export const formatInitialTime = ({
  initialTime,
  buffer,
  roundTo = 15,
  locale,
  timezone = getMyTimezoneId(),
}: FormatInitialTimeArgs): string => {
  if (initialTime) {
    // We need to check if booking settings for initial time is in the past or not
    const targetedDateTime = mergeDateTime(new Date(), initialTime);
    const nowDateTime = toZonedTime(new Date(), timezone);

    if (targetedDateTime > nowDateTime) {
      return initialTime;
    }
  }

  return format(
    roundToNearestMinutes(addMinutes(toZonedTime(new Date(), timezone), buffer), {
      nearestTo: +roundTo as NearestMinutes,
    }),
    locale ? 'p' : 'HH:mm',
    {
      locale,
    },
  );
};
