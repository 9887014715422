import React, { FC } from 'react';
import styles from '@/core/assets/scss/components/Dashboard/Sidebar.module.scss';
import MainMenu from '@/core/components/Dashboard/MainMenu';
import { Button, Column, Row } from '@gourban/ui-components';
import { useNavigate } from 'react-router';
import { Trans } from '@lingui/react/macro';
import ContactSupport from '@/core/components/Dashboard/ContactSupport';
import useFeatureCheck from '@/features/auth/hooks/useFeatureCheck';
import { TenantFeatures } from '@/features/auth/enums';
import useRequestUserSignOut from '@/features/auth/hooks/useRequestUserSignOut';
import WhiteLabelLogo from '@/core/components/UI/WhiteLabelLogo';
import { useResponsive } from '@/core/hooks/useResponsive';

interface SidebarT {
  onResponsiveClose?: () => void;
}

const Sidebar: FC<SidebarT> = ({ onResponsiveClose }) => {
  const navigate = useNavigate();
  const hasFeature = useFeatureCheck();
  const onSignOutClick = useRequestUserSignOut();
  const { isMobile } = useResponsive();

  return (
    <aside className={styles.sidebar}>
      <div className={styles.sidebar__main}>
        <div className={styles['sidebar__main-logo']}>
          <WhiteLabelLogo type="small" />

          {isMobile && (
            <Button
              className={styles['sidebar__responsive-close']}
              variation="link"
              iconName="close"
              onClick={onResponsiveClose}
            />
          )}
        </div>

        <MainMenu />

        <Row justify="flex-start" className={styles['sidebar__main-footer']}>
          <Column sm={12} marginBottom={16}>
            <ContactSupport />
          </Column>

          <Column sm={12}>
            {hasFeature(TenantFeatures.ENDUSER_WEB_APP) ? (
              <Button onClick={() => navigate('/')} size="small" width="full">
                <Trans id="sidebar.bookings">Booking</Trans>
              </Button>
            ) : (
              <Button onClick={onSignOutClick} size="small" width="full">
                <Trans id="header.dropdown.logout">Logout</Trans>
              </Button>
            )}
          </Column>
        </Row>
      </div>
    </aside>
  );
};

export default Sidebar;
