import React, { FC, memo } from 'react';
import { PaymentSourceBrand, PaymentSourceType } from '@/features/payment/types/Payment.types';
import visa from '@/core/assets/icons/visa.svg';
import mastercard from '@/core/assets/icons/mastercard.svg';
import amex from '@/core/assets/icons/amex.svg';
import diners from '@/core/assets/icons/diners.svg';
import maestro from '@/core/assets/icons/maestro.svg';
import paypall from '@/core/assets/icons/paypall.svg';
import googlePay from '@/core/assets/icons/googlePay.svg';
import applyPay from '@/core/assets/icons/applePay.svg';
import styles from '../assets/CreditCardLogo.module.scss';

interface CreditCardLogoT {
  brand?: PaymentSourceBrand;
  type: PaymentSourceType;
}

const CreditCardLogo: FC<CreditCardLogoT> = ({ brand, type }) => {
  let src;

  if (type === 'CC') {
    switch (brand) {
      case 'mc':
        src = mastercard;
        break;
      case 'visa':
        src = visa;
        break;
      case 'amex':
        src = amex;
        break;
      case 'diners':
        src = diners;
        break;
      case 'maestro':
        src = maestro;
        break;
      case 'other':
      default:
        return null;
    }
  }

  if (type === 'GOOGLEPAY') {
    src = googlePay;
  }

  if (type === 'PAYPAL') {
    src = paypall;
  }

  if (type === 'APPLEPAY') {
    src = applyPay;
  }

  return <img className={styles['credit-card-logo']} src={src} alt={brand} />;
};

export default memo(CreditCardLogo);
