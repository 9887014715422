import { FC } from 'react';
import { useRequestForgotPasswordMutation } from '@/features/account/services/Account.service';
import { Alert, Button, Column, Row, Text } from '@gourban/ui-components';
import { Form, Formik } from 'formik';
import { ApiError } from '@/core/types';
import { forgotPasswordSchema } from '@/features/auth/utils/validations/forgotPassword.schema';
import { ForgotPasswordArgs } from '@/features/auth/types/Auth.types';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';

export interface ForgotPasswordFormT {
  onForgotPasswordSuccess: () => void;
}

const ForgotPasswordForm: FC<ForgotPasswordFormT> = ({ onForgotPasswordSuccess }) => {
  const [requestForgotPassword, { error }] = useRequestForgotPasswordMutation();

  const onSubmitForm = (args: ForgotPasswordArgs) =>
    requestForgotPassword(args).unwrap().then(onForgotPasswordSuccess);

  const translations = {
    email: t({
      id: 'auth.forms.email',
      message: 'E-mail',
    }),
  };

  return (
    <Formik<ForgotPasswordArgs>
      initialValues={{ email: '' }}
      validationSchema={forgotPasswordSchema}
      onSubmit={onSubmitForm}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            {!!error && (
              <Alert
                full
                type="error"
                title={t({
                  id: 'error',
                  message: 'Error',
                })}
                description={(error as ApiError).userMessage!}
              />
            )}

            <Row marginBottom={8} gapSm="sm" column>
              <Column>
                <Text
                  name="email"
                  fieldAttr={{
                    id: 'email',
                    placeholder: translations.email,
                    autoComplete: 'email',
                    type: 'email',
                    required: true,
                  }}
                  fieldProps={{
                    size: 'large',
                    label: translations.email,
                  }}
                />
              </Column>
            </Row>

            <Row>
              <Button
                loading={isSubmitting}
                width="full"
                size="large"
                attributes={{ type: 'submit' }}
              >
                <Trans id="general.send">Send</Trans>
              </Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ForgotPasswordForm;
