/* eslint-disable */
import React, { FC, useEffect, useMemo } from 'react';
import { RouterProvider } from 'react-router';
import AuthGuard from '@/features/auth/components/AuthGuard';
import Page404 from '@/core/pages/Page404';
import routes, { businessAccountOnlyModeRoutes } from './routerConfig';
import { createRouter } from '@/core/pages/utils/createRouter';
import useFeatureCheck from '@/core/features/auth/hooks/useFeatureCheck';
import { TenantFeatures } from '@/features/auth/enums';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { TenantAccountSettings } from '@/features/geomap/types';
import { SettingsKeys } from '@/core/enums';
import TenantError from '@/features/auth/components/TenantError';
import { useRequestWhitelabelQuery } from '@/core/services/WhiteLabeling.service';
import { Loader, useGoUrbanConfigContext } from '@gourban/ui-components';

const Router: FC = () => {
  const hasFeature = useFeatureCheck();
  const { setConfig } = useGoUrbanConfigContext();
  const hasActiveBusinessAccounts = useTypedSelector((state) =>
    getSetting<TenantAccountSettings>(state, SettingsKeys.TENANT_ACCOUNTS),
  );
  const { isLoading: isFetchingWhiteLabeling } = useRequestWhitelabelQuery();

  useEffect(() => {
    setConfig!((prev) => ({ ...prev, mapboxToken: import.meta.env.VITE_MAPBOX_ACCESSTOKEN }));
  }, []);

  const router = useMemo(() => {
    if (
      !hasActiveBusinessAccounts?.businessAccounts?.active &&
      !hasFeature(TenantFeatures.ENDUSER_WEB_APP)
    ) {
      return createRouter({
        wildcardElement: <TenantError />,
      });
    }

    if (hasFeature(TenantFeatures.ENDUSER_WEB_APP)) {
      return createRouter({
        routes,
        wildcardElement: (
          <AuthGuard>
            <Page404 />
          </AuthGuard>
        ),
      });
    }

    return createRouter({
      routes: businessAccountOnlyModeRoutes,
      wildcardElement: (
        <AuthGuard>
          <Page404 />
        </AuthGuard>
      ),
    });
  }, [hasFeature, hasActiveBusinessAccounts]);

  if (isFetchingWhiteLabeling) {
    return <Loader cover fixed />;
  }

  return <RouterProvider router={router} />;
};

export default Router;
