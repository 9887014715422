import React, { FC } from 'react';
import TripDrivingLog from '@/features/myTrips/components/TripDrivingLog/TripDrivingLog';
import { Outlet, useNavigate, useParams } from 'react-router';
import { useRequestBookingByIdQuery } from '@/features/booking/services/Booking.service';
import { Loader, Row } from '@gourban/ui-components';
import ErrorFallback from '@/core/components/ErrorHandlers/ErrorFallback';
import FullScreen from '@/core/components/Layouts/FullScreen';
import WhiteLabelLogo from '@/core/components/UI/WhiteLabelLogo';
import UserAvatarDropdown from '@/core/components/UI/UserAvatarDropdown';

interface DrivingLogT {
  isReadOnly?: boolean;
}

const DrivingLog: FC<DrivingLogT> = ({ isReadOnly }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: trip, isFetching, isError } = useRequestBookingByIdQuery({ id: +id! });

  return (
    <FullScreen
      mainSize="wide"
      title={<WhiteLabelLogo />}
      actions={<UserAvatarDropdown />}
      onClose={() => navigate('/my-trips/past')}
      main={
        <>
          {isFetching && (
            <Row justify="center">
              <Loader />
            </Row>
          )}
          {!isFetching && trip && (
            <>
              <TripDrivingLog isReadOnly={isReadOnly} trip={trip} />
              <Outlet context={trip} />
            </>
          )}
          {!isFetching && isError && <ErrorFallback />}
        </>
      }
    />
  );
};

export default DrivingLog;
