import { FC, useEffect, useMemo } from 'react';
import {
  AvailableBookingStationArgs,
  BookingFiltersForm,
  DateFilterTypes,
} from '@/features/booking/types';
import FiltersForm from '@/features/booking/components/Filters/FiltersForm';
import { getNumberOfActiveFilters } from '@/features/booking/utils/getNumberOfActiveFilters';
import { formatInitialTime } from '@/features/booking/utils/formatInitialTime';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import { plural, t } from '@lingui/core/macro';
import { useTypedDispatch } from '@/core/redux/hooks';
import { resetActiveFilters } from '@/features/account/redux/account.reducer';
import { formatDateLabelString } from '../../utils/formatDateLabelString';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { tryJSONParse } from '@/core/utils/tryJSONParse';

interface FiltersT {
  onSubmit: (args: Partial<AvailableBookingStationArgs>) => Promise<unknown> | null;
}

/**
 * Filters are being portaled to the header from here because of its needs for map context to be able to navigate around the map
 * as well as filtering different geoJSON layers
 */
const Filters: FC<FiltersT> = ({ onSubmit }) => {
  const dispatch = useTypedDispatch();
  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const locale = useDateLocale();

  const initialParams = useMemo(() => {
    const URLParams = new URLSearchParams(window.location.search);
    const bookingFilters = URLParams.get('bookingFilters');
    let parsedFilters = {} as BookingFiltersForm;
    if (bookingFilters) {
      parsedFilters = tryJSONParse(decodeURIComponent(bookingFilters), {});
    }

    return parsedFilters;
  }, []);

  const initialValues = useMemo<BookingFiltersForm>(() => {
    const initialFilters = initialParams;

    const activeFilterNumber = getNumberOfActiveFilters(initialFilters.vehicleAttributes);
    const tab: DateFilterTypes = initialFilters?.dateFilterType ?? 'SPECIFIC';

    const datePlaceHolder = formatDateLabelString(
      tab,
      {
        flexibleDurationType: initialFilters?.flexibleDurationType,
        bookingDate: initialFilters.bookingDate,
        bookingTime: initialFilters.bookingTime,
      },
      locale,
    );

    const bookingTime = initialFilters.bookingTime ?? {
      from: formatInitialTime({
        initialTime: bookingSettings?.value?.properties?.preselectedStartingTime,
        buffer: 60,
      }),
      to: formatInitialTime({
        initialTime: bookingSettings?.value?.properties?.preselectedEndingTime,
        buffer: 120,
      }),
    };

    return {
      location: initialFilters?.location ?? '',
      date: datePlaceHolder,
      dateFilterType: tab,
      bookingDate: initialFilters.bookingDate
        ? {
            from: new Date(initialFilters.bookingDate?.from!),
            to: new Date(initialFilters.bookingDate?.to!),
          }
        : undefined,
      bookingTime,
      flexibleDurationType: initialFilters?.flexibleDurationType,
      boundingBoxCoordinates: initialFilters?.boundingBoxCoordinates,
      vehicleAttributes: initialFilters.vehicleAttributes,
      vehicleFilters: activeFilterNumber
        ? t({
            id: 'bookings.filters.activeFilterNumber',
            message: plural(activeFilterNumber, {
              one: '# Filter',
              other: '# Filters',
            }),
          })
        : undefined,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingSettings]);

  // We need to reset filters when user leaves the screen containing them.
  useEffect(() => {
    return () => {
      dispatch(resetActiveFilters());
    };
  }, [dispatch]);

  return (
    <FiltersForm
      bookingSettings={bookingSettings}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};

export default Filters;
