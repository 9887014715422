import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Column, Row } from '@gourban/ui-components';
import cn from 'classnames';
import styles from '@/features/booking/assets/scss/components/Filters/Filters.module.scss';
import DateFilter from '@/features/booking/components/Filters/DateFilter';
import { Form, useFormikContext } from 'formik';
import { BookingFiltersForm } from '@/features/booking/types';
import { useLocation, useParams } from 'react-router';
import { Trans } from '@lingui/react/macro';
import { useLingui } from '@lingui/react';
import LocationFilter from '@/features/booking/components/Filters/LocationFilter';
import VehicleAttributesFilter from '@/features/booking/components/Filters/VehicleAttributesFilter';

const FormFields: FC = () => {
  const [dateDropdownVisible, setDateDropdownVisible] = useState<boolean>(false);
  const { values, isSubmitting } = useFormikContext<BookingFiltersForm>();
  const { branchId } = useParams();
  const [filtersDirty, setFiltersDirty] = useState<boolean>(false);
  const location = useLocation();
  useLingui();

  const previousValues = useRef<Pick<BookingFiltersForm, 'vehicleFilters' | 'location' | 'date'>>({
    vehicleFilters: values.vehicleFilters,
    location: values.location,
    date: values.date,
  });

  useEffect(() => {
    const hasChanges = Object.entries(previousValues.current).some(([key, value]) => {
      return (
        value !==
        values[key as keyof Pick<BookingFiltersForm, 'vehicleFilters' | 'location' | 'date'>]
      );
    });

    // If some of these values changes, we need to set filters to be dirty programmatically
    if (!filtersDirty && hasChanges) setFiltersDirty(true);

    if (hasChanges) {
      previousValues.current = {
        vehicleFilters: values.vehicleFilters,
        location: values.location,
        date: values.date,
      };
    }

    // eslint-disable-next-line
  }, [values.vehicleFilters, values.location, values.date, isSubmitting]);

  const isReadOnly = location.pathname === `/booking/${branchId}`;

  return (
    <Form className={cn(styles.filters__form)}>
      <Row noWrap justify="space-between" className={styles.filters__form__container}>
        <Column className={styles['filters__form__container-item']} sm={4}>
          <LocationFilter
            isReadOnly={isReadOnly}
            onChange={() => {
              if (!values.date && !values.vehicleFilters) {
                setDateDropdownVisible(true);
              }
            }}
          />
        </Column>
        <Column className={styles['filters__form__container-item']} grow>
          <DateFilter dropdownVisible={dateDropdownVisible} readOnly={isReadOnly} />
        </Column>
        <Column
          className={cn(
            styles['filters__form__container-item'],
            styles['filters__form__container-item--vehicle-filters'],
          )}
          sm={4}
        >
          <Row noWrap>
            <Column grow>
              <VehicleAttributesFilter isReadOnly={isReadOnly} />
            </Column>

            <Column className={styles['filters__form-submit']}>
              <Button
                disabled={isReadOnly}
                attributes={{
                  type: isReadOnly ? 'button' : 'submit',
                  'data-testid': 'FilterSearchSubmit',
                }}
                size="small"
                variation="primary"
                iconSize={{ width: 16, height: 20 }}
                iconName="search"
                onClick={() => {
                  setTimeout(() => {
                    setFiltersDirty(false);
                  }, 0);
                }}
                className={cn(isReadOnly && styles['filters__form-submit--hidden'])}
              >
                {filtersDirty && <Trans id="general.search">Search</Trans>}
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
    </Form>
  );
};

export default FormFields;
