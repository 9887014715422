import React, { FC, ReactNode } from 'react';
import { Card, Column, Paragraph, Row } from '@gourban/ui-components';
import styles from '@/features/account/assets/Edit/AccountBusinessCard.module.scss';
import { Trans } from '@lingui/react/macro';

interface AccountSettingsBusinessCardT {
  action: ReactNode;
  name: ReactNode;
}

const BusinessCard: FC<AccountSettingsBusinessCardT> = ({ name, action }) => {
  return (
    <Card className={styles['account-business-card']}>
      <Row marginBottom={0} alignItems="center" justify="space-between">
        <Column>
          <Paragraph marginBottom={2} size={4} textColor="text-400">
            <Trans id="accountEdit.businessCard.title">Business account</Trans>
          </Paragraph>
          {name}
        </Column>
        <Column>{action}</Column>
      </Row>
    </Card>
  );
};

export default BusinessCard;
