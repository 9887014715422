import React from 'react';
import { Flex, Paragraph } from '@gourban/ui-components';
import { useResponsive } from '@/core/hooks/useResponsive';
import { useDetectDevice } from '@/core/hooks/useDetectDevice';
import styles from '@/core/assets/scss/components/DownloadAppBanner.module.scss';
import { Trans } from '@lingui/react/macro';
import { useRequestTenantInfoQuery } from '@/features/auth/services/Tenant.service';

const DownloadAppBanner = () => {
  const { isMobile } = useResponsive();
  const device = useDetectDevice();
  const { data: tenantData } = useRequestTenantInfoQuery();

  if (isMobile && device === 'Android' && tenantData?.publicProperties?.androidAppID) {
    return (
      <Flex align="center" className={styles['download-app-banner']} justify="space-between">
        <Paragraph marginBottom={0} size={4} textColor="text-600">
          <Trans id="components.downloadAppBanner.title">Get the best experience on the app</Trans>
        </Paragraph>

        <a
          href={`https://play.google.com/store/apps/details?id=${tenantData.publicProperties.androidAppID}`}
        >
          <img
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            alt="Google Play Store"
          />
        </a>
      </Flex>
    );
  }

  if (isMobile && device === 'iOS' && tenantData?.publicProperties?.iOSAppID) {
    return (
      <Flex align="center" className={styles['download-app-banner']} justify="space-between">
        <Paragraph marginBottom={0} size={4} textColor="text-600">
          <Trans id="components.downloadAppBanner.title">Get the best experience on the app</Trans>
        </Paragraph>

        <a href={`https://apps.apple.com/app/id${tenantData.publicProperties.iOSAppID}`}>
          <img
            src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"
            alt="App Store"
          />
        </a>
      </Flex>
    );
  }

  return null;
};

export default DownloadAppBanner;
