import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { RestClientWrapper } from '@/core/api/restClientWrapper';
import {
  PackageInfo,
  BuyPackageArgs,
  PackageInfoWithPromoCodeArgs,
  PackageInfoWithPromoCode,
  PaymentSourceRemovalArgs,
} from '@/features/account/types';
import {
  SessionData,
  BuyPackageResponse,
  PaymentSource,
} from '@/features/payment/types/Payment.types';
import { Branch } from '@/features/branches/types';
import { AccountCacheTags } from '@/features/account/enums';
import { stringify } from 'qs';
import { NotificationStore } from '@gourban/ui-components';
import { t } from '@lingui/core/macro';
import { omit } from 'lodash';

export const PaymentService = createApi({
  reducerPath: 'api-payment',
  baseQuery: RestClientWrapper,
  tagTypes: [AccountCacheTags.PAYMENT_SOURCES],
  endpoints: (builder) => ({
    requestPaymentSessionData: builder.query<
      SessionData,
      { userGroupId?: number; branchId?: number }
    >({
      query: ({ userGroupId, branchId }) => {
        const params = stringify({ userGroupId, branchId });

        return {
          url: `/front/payment/cc?${params}`,
        };
      },
    }),

    requestPaymentOnSuccessNotifier: builder.query<
      void,
      { sessionResult: string; onSuccessURL: string }
    >({
      query: ({ sessionResult, onSuccessURL }) => {
        const params = stringify({ sessionResult }, { encode: false });

        return {
          url: `${onSuccessURL}?${params}`,
        };
      },
    }),

    requestPackageInformation: builder.query<PackageInfo | undefined, Branch['id']>({
      query: (branchId) => ({
        url: '/front/packages',
        requestParams: {
          params: {
            branchId,
            hasValue: true,
            signup: true,
          },
        },
      }),
      transformResponse: (data: PackageInfo[]) => data[0],
    }),

    requestPackageInformationWithPromoCode: builder.query<
      PackageInfoWithPromoCode,
      PackageInfoWithPromoCodeArgs
    >({
      query: ({ packageCode, promotionCode, branchId }) => ({
        url: `/front/packages/${packageCode}/pricing?branchId=${branchId}`,
        requestParams: {
          params: {
            promotionCode,
          },
        },
      }),
    }),

    requestPaymentSources: builder.query<
      PaymentSource[],
      { userGroupId?: number; branchId?: number }
    >({
      query: ({ userGroupId, branchId }) => {
        const params = stringify(
          { userGroupId, branchId, state: ['PENDING', 'ACTIVE'] },
          { arrayFormat: 'comma' },
        );

        return {
          url: `/front/payment?${params}`,
        };
      },
      // We wanna fetch latest sources everytime
      keepUnusedDataFor: 0,
      providesTags: [AccountCacheTags.PAYMENT_SOURCES],
    }),

    requestSetDefaultPaymentSource: builder.mutation<
      void,
      { sourceId: string; userGroupId?: number; branchId?: number }
    >({
      query: (data) => ({
        url: `/front/payment?branchId=${data.branchId}`,
        requestParams: {
          method: 'PUT',
          data: omit(data, ['branchId']),
        },
        config: {
          onRequestSuccess: () => {
            NotificationStore.addNotification({
              type: 'success',
              title: t({ id: 'general.notification.success', message: 'Success' }),
              content: t({
                id: 'account.notification.paymentUpdateSuccess',
                message: 'Preferred payment updated successfully.',
              }),
            });
          },
        },
      }),
      invalidatesTags: [AccountCacheTags.PAYMENT_SOURCES],
    }),

    requestBuyPackage: builder.mutation<BuyPackageResponse, BuyPackageArgs>({
      query: (data) => ({
        url: '/front/packages/buy',
        requestParams: {
          method: 'POST',
          data,
        },
      }),
    }),

    requestPaymentSourceDelete: builder.mutation<void, PaymentSourceRemovalArgs>({
      query: ({ userGroupId, sourceId, branchId }) => {
        const params = stringify({ userGroupId, branchId });

        return {
          url: `/front/payment?${params}`,
          requestParams: {
            method: 'DELETE',
            data: { sourceId },
          },
        };
      },
      invalidatesTags: [AccountCacheTags.PAYMENT_SOURCES],
    }),
  }),
});

export const {
  useLazyRequestPaymentOnSuccessNotifierQuery,
  useLazyRequestPaymentSourcesQuery,
  useRequestPaymentSessionDataQuery,
  useLazyRequestPaymentSessionDataQuery,
  useRequestPackageInformationQuery,
  useLazyRequestPackageInformationWithPromoCodeQuery,
  useRequestPaymentSourcesQuery,
  useRequestSetDefaultPaymentSourceMutation,
  useRequestBuyPackageMutation,
  useRequestPaymentSourceDeleteMutation,
} = PaymentService;
