import { RestClientWrapper } from '@/core/api/restClientWrapper';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  DrivingLogCreateForm,
  DrivingLogEntry,
  RentalDrivingLog,
} from '@/features/myTrips/types/DrivingLog';
import { DrivingLogCacheTags } from '@/features/myTrips/enums';
import { omit } from 'lodash';
import { NotificationStore } from '@gourban/ui-components';
import { t } from '@lingui/core/macro';

export const DrivingLogService = createApi({
  reducerPath: 'api-driving-log',
  baseQuery: RestClientWrapper,
  tagTypes: [DrivingLogCacheTags.DRIVING_LOG_ENTRIES, DrivingLogCacheTags.DRIVING_LOG_ENTRY],
  endpoints: (builder) => ({
    requestDrivingLog: builder.query<RentalDrivingLog, number>({
      query: (rentalId) => {
        return {
          url: `/front/rentals/${rentalId}/driving-log`,
        };
      },
      providesTags: [DrivingLogCacheTags.DRIVING_LOG_ENTRIES],
    }),

    requestDrivingLogSubmit: builder.mutation<void, number>({
      query: (rentalId) => {
        return {
          url: `/front/rentals/${rentalId}/driving-log/submit`,
          requestParams: {
            method: 'POST',
          },
          config: {
            onRequestSuccess: () => {
              NotificationStore.addNotification({
                type: 'success',
                title: t({ id: 'general.notification.success', message: 'Success' }),
                content: t({
                  id: 'myTrips.notification.drivingLogEntrySubmitSuccess',
                  message: 'Driving log successfully submitted!',
                }),
              });
            },
          },
        };
      },
      invalidatesTags: [DrivingLogCacheTags.DRIVING_LOG_ENTRIES],
    }),

    requestDrivingLogEntry: builder.query<
      DrivingLogEntry,
      { rentalId: number; drivingLogEntryId: number }
    >({
      query: ({ rentalId, drivingLogEntryId }) => {
        return {
          url: `/front/rentals/${rentalId}/driving-log/entries/${drivingLogEntryId}`,
        };
      },
      providesTags: (drivingLogEntry) => {
        if (!drivingLogEntry) {
          return [];
        }

        return [{ type: DrivingLogCacheTags.DRIVING_LOG_ENTRY, id: drivingLogEntry.id }];
      },
    }),

    requestCreateDrivingLogEntry: builder.mutation<
      void,
      DrivingLogCreateForm & { rentalId: number }
    >({
      query: (data) => {
        return {
          url: `/front/rentals/${data.rentalId}/driving-log/entries`,
          requestParams: {
            method: 'POST',
            data: omit(data, ['rentalId']),
          },
          config: {
            onRequestSuccess: () => {
              NotificationStore.addNotification({
                type: 'success',
                title: t({ id: 'general.notification.success', message: 'Success' }),
                content: t({
                  id: 'myTrips.notification.drivingLogEntryCreateSuccess',
                  message: 'Driving log entry successfully created!',
                }),
              });
            },
          },
        };
      },
      invalidatesTags: [DrivingLogCacheTags.DRIVING_LOG_ENTRIES],
    }),

    requestRemoveDrivingLogEntry: builder.mutation<
      void,
      { drivingLogEntryId: number; rentalId: number }
    >({
      query: ({ drivingLogEntryId, rentalId }) => {
        return {
          url: `/front/rentals/${rentalId}/driving-log/entries/${drivingLogEntryId}`,
          requestParams: {
            method: 'DELETE',
          },
          config: {
            onRequestSuccess: () => {
              NotificationStore.addNotification({
                type: 'success',
                title: t({ id: 'general.notification.success', message: 'Success' }),
                content: t({
                  id: 'myTrips.notification.drivingLogEntryRemovedSuccess',
                  message: 'Driving log entry successfully removed',
                }),
              });
            },
          },
        };
      },
      invalidatesTags: [DrivingLogCacheTags.DRIVING_LOG_ENTRIES],
    }),

    requestUpdateDrivingLogEntry: builder.mutation<
      DrivingLogEntry,
      DrivingLogCreateForm & { drivingLogEntryId: number; rentalId: number }
    >({
      query: ({ drivingLogEntryId, rentalId, ...values }) => {
        return {
          url: `/front/rentals/${rentalId}/driving-log/entries/${drivingLogEntryId}`,
          requestParams: {
            method: 'PUT',
            data: values,
          },
          config: {
            onRequestSuccess: () => {
              NotificationStore.addNotification({
                type: 'success',
                title: t({ id: 'general.notification.success', message: 'Success' }),
                content: t({
                  id: 'myTrips.notification.drivingLogEntryUpdateSuccess',
                  message: 'Driving log entry successfully updated',
                }),
              });
            },
          },
        };
      },
      invalidatesTags: (drivingLogEntry) => {
        if (!drivingLogEntry) {
          return [DrivingLogCacheTags.DRIVING_LOG_ENTRIES];
        }

        return [
          DrivingLogCacheTags.DRIVING_LOG_ENTRIES,
          { type: DrivingLogCacheTags.DRIVING_LOG_ENTRY, id: drivingLogEntry.id },
        ];
      },
    }),
  }),
});

export const {
  useRequestDrivingLogQuery,
  useRequestDrivingLogEntryQuery,
  useRequestDrivingLogSubmitMutation,
  useRequestUpdateDrivingLogEntryMutation,
  useRequestCreateDrivingLogEntryMutation,
  useRequestRemoveDrivingLogEntryMutation,
} = DrivingLogService;
