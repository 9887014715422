import { t } from '@lingui/core/macro';

export const CommonParamTranslations = {
  removeConfirmationTitle: (entity: string) =>
    t({ id: 'general.removeConfirmation.title', message: `Remove ${entity}` }),
  removeConfirmationDescription: (value: string) =>
    t({
      id: 'general.removeConfirmation.description',
      message: `Are you sure you want to remove ${value}?`,
    }),
};
