import React, { useCallback, useEffect, useRef } from 'react';
import {
  Column,
  Paragraph,
  Row,
  DatePickerCalendar,
  TimePickerIntervals,
} from '@gourban/ui-components';
import styles from '../../assets/scss/components/BookingFlow/DateSelect.module.scss';
import { addDays, format, isAfter, isBefore, max, min } from 'date-fns';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import { useFormikContext } from 'formik';
import { BookingOverviewForm } from '@/features/booking/types';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { DateFormats } from '@/core/enums';
import { useResponsive } from '@/core/hooks/useResponsive';
import { DateRange } from '@/core/types';
import { formatInitialTime } from '@/features/booking/utils/formatInitialTime';

const SpecificDate = () => {
  const { values, setFieldValue, initialValues } = useFormikContext<BookingOverviewForm>();
  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const { isMobile } = useResponsive();
  const dateLocale = useDateLocale();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const shouldBeDisabled = useCallback(
    (value: string | null, datePeriod: 'from' | 'to') => {
      const { bookingTime, bookingDate } = values;

      if (!bookingTime || !value) {
        return false;
      }

      const dateReference =
        datePeriod === 'from'
          ? (bookingDate?.from ?? new Date())
          : (bookingDate?.to ?? bookingDate?.from ?? new Date());

      const dateTime = mergeDateTime(dateReference, value);

      const minStartTime = max([new Date()]);
      const maxStartTime = min([
        mergeDateTime(bookingDate?.to ?? bookingDate?.from ?? new Date(), bookingTime?.to),
      ]);

      const minEndTime = max([mergeDateTime(bookingDate?.from ?? new Date(), bookingTime?.from)]);
      const maxEndTime = min([]);

      if (datePeriod === 'from') {
        return (
          isBefore(dateTime, minStartTime) ||
          format(dateTime, DateFormats.FULL_DATE_TIME) ===
            format(maxStartTime, DateFormats.FULL_DATE_TIME) ||
          isAfter(dateTime, maxStartTime)
        );
      }

      return (
        isBefore(dateTime, minEndTime) ||
        format(dateTime, DateFormats.FULL_DATE_TIME) ===
          format(minEndTime, DateFormats.FULL_DATE_TIME) ||
        isAfter(dateTime, maxEndTime)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values],
  );

  useEffect(() => {
    const defaultStartTime = formatInitialTime({
      initialTime: bookingSettings?.value?.properties?.preselectedStartingTime,
      buffer: 60,
      roundTo: bookingSettings?.value?.properties?.timeGap,
    });

    const defaultEndTime = formatInitialTime({
      initialTime: bookingSettings?.value?.properties?.preselectedEndingTime,
      buffer: 120,
      roundTo: bookingSettings?.value?.properties?.timeGap,
    });

    if (!values?.bookingDate?.from) {
      void setFieldValue('bookingTime.from', defaultStartTime);
      void setFieldValue('bookingTime.to', defaultEndTime);
      return;
    }

    // We need to resolve cases where future day is selected as well as time, and changing date in the past would make time invalid, so we need to reset it
    if (
      values.bookingDate.from !== initialValues.bookingDate?.from &&
      values.bookingTime?.from &&
      mergeDateTime(values.bookingDate.from, values.bookingTime?.from) < new Date()
    ) {
      void setFieldValue('bookingTime.from', defaultStartTime);
      void setFieldValue(
        'bookingDate.from',
        mergeDateTime(values.bookingDate.from, defaultStartTime),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.bookingDate]);

  return (
    <div ref={containerRef} className={styles['date-select']}>
      <Row marginBottom={0} alignItems="center" gapSm="xs" justify="space-between">
        <Column sm={12} md={6}>
          <Paragraph marginBottom={10} size={2}>
            <Trans id="bookings.filters.selectDate">Select date</Trans>
          </Paragraph>
          <Paragraph marginBottom={0} size={3} textColor="text-400">
            {values.bookingDate?.from ? (
              `${format(values.bookingDate?.from, DateFormats.SHORT_DATE, {
                locale: dateLocale,
              })} - ${format(
                values.bookingDate?.to ?? values.bookingDate?.from,
                DateFormats.SHORT_DATE,
                { locale: dateLocale },
              )}`
            ) : (
              <Trans id="bookings.filters.noDateSelected">No date selected</Trans>
            )}
          </Paragraph>
        </Column>
        <Column sm={12} md={6}>
          <Row marginBottom={0} alignItems="center" gapSm="xs" justify="space-between">
            <Column className={styles['date-select__from']} sm={6}>
              <TimePickerIntervals
                fieldAttr={{
                  id: 'from',
                  required: true,
                }}
                fieldProps={{
                  label: t({ id: 'bookings.filters.from', message: 'From' }),
                  size: 'small',
                  iconLeft: 'clock',
                  timeInterval: (bookingSettings?.value?.properties?.timeGap as number) ?? 30,
                  isDisabled: (val) => shouldBeDisabled(val!, 'from'),
                  onChange: (selectedTime) => {
                    void setFieldValue(
                      'bookingDate.from',
                      mergeDateTime(values.bookingDate?.from ?? new Date(), selectedTime),
                    );
                  },
                  boundingBox: containerRef,
                }}
                name="bookingTime.from"
              />
            </Column>
            <Column sm={6}>
              <TimePickerIntervals
                fieldAttr={{ id: 'to', required: true }}
                fieldProps={{
                  label: t({ id: 'bookings.filters.to', message: 'Until' }),
                  size: 'small',
                  iconLeft: 'clock',
                  timeInterval: (bookingSettings?.value?.properties?.timeGap as number) ?? 30,
                  isDisabled: (val) => {
                    return shouldBeDisabled(val!, 'to');
                  },
                  onChange: (selectedTime) => {
                    if (values.bookingDate?.from || values.bookingDate?.to) {
                      void setFieldValue(
                        'bookingDate.to',
                        mergeDateTime(
                          values.bookingDate?.to ?? values.bookingDate?.from!,
                          selectedTime,
                        ),
                      );
                    }
                  },
                  boundingBox: containerRef,
                }}
                name="bookingTime.to"
              />
            </Column>
          </Row>
        </Column>
      </Row>
      <div className={styles['date-select__calendar']}>
        <DatePickerCalendar
          fieldProps={{
            isRange: true,
            allowableSelectionRange: {
              minDate: new Date().toString(),
              maxDate: addDays(
                new Date(),
                bookingSettings?.value.properties.maxDateRange ?? 365,
              ).toString(),
            },
            onChange: (dateRange) => {
              if (dateRange) {
                // We need to include time in these selected dates
                void setFieldValue('bookingDate', {
                  from: mergeDateTime((dateRange as DateRange)?.from, values.bookingTime?.from),
                  to:
                    (dateRange as DateRange)?.to &&
                    mergeDateTime((dateRange as DateRange)?.to, values.bookingTime?.to),
                });
              }
            },
            markedDateColor: 'var(--y-400)',
            numberOfMonths: isMobile ? 1 : 2,
          }}
          name="bookingDate"
        />
      </div>
    </div>
  );
};

export default SpecificDate;
