import { FC } from 'react';
import { useLocation } from 'react-router';
import { RouteStaticConfig } from '@/core/types';
import StepButton from '@/core/components/StepButton';
import { BusinessAccountCreationSteps } from '@/features/businessAccounts/types';
import { useLingui } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';
import { useRequestBusinessAccountQuery } from '@/features/businessAccounts/services/BusinessAccount.service';
import { businessAccountCreateCompanyInfoStep } from '@/core/pages/BusinessAccounts/routes';
import styles from '@/features/account/assets/Onboarding/Stepper.module.scss';

interface Step extends RouteStaticConfig {
  stepName: BusinessAccountCreationSteps;
}

interface BusinessAccountStepperT {
  steps: Step[];
}

interface StepStatus {
  isActive: boolean;
  isCompleted: boolean;
}

const isRouteActive = (route: RouteStaticConfig, pathname: string): boolean =>
  Boolean(route.path === pathname || route.additionalPaths?.includes(pathname));

const BusinessAccountStepper: FC<BusinessAccountStepperT> = ({ steps }) => {
  const { data: businessAccount } = useRequestBusinessAccountQuery();
  const { pathname } = useLocation();
  const { i18n } = useLingui();

  const completedSteps: Record<BusinessAccountCreationSteps, StepStatus> = {
    'ba-company-info': {
      isCompleted: !!businessAccount,
      isActive: isRouteActive(businessAccountCreateCompanyInfoStep, pathname),
    },
    'ba-payment': {
      isCompleted: false,
      isActive: !!businessAccount,
    },
  };

  return (
    <div className={styles.stepper}>
      {steps.map((step, index) => {
        const disabled =
          !completedSteps[step.stepName].isCompleted && !completedSteps[step.stepName].isActive;

        return (
          <StepButton
            key={step.stepName}
            isActive={isRouteActive(step, pathname)}
            url={step.path}
            navigateOptions={{
              state: {
                isFromStepSwitching: true,
              },
            }}
            label={i18n._(step.label as MessageDescriptor)}
            stepNumber={index + 1}
            isCompleted={completedSteps[step.stepName].isCompleted}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default BusinessAccountStepper;
