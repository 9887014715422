import { number, object, string } from 'yup';
import { CommonValidationTranslations } from '@/core/utils/commonValidationTranslations';
import { t } from '@lingui/core/macro';

export const drivingLogSchema = object().shape({
  startDate: string()
    .trim()
    .required(() => CommonValidationTranslations.requiredField()),
  startLocation: string()
    .trim()
    .required(() => CommonValidationTranslations.requiredField()),
  endLocation: string()
    .trim()
    .required(() => CommonValidationTranslations.requiredField()),
  reason: string()
    .trim()
    .required(() => CommonValidationTranslations.requiredField()),
  startKilometers: number()
    .positive(() => CommonValidationTranslations.number.atLeast({ value: 0 }))
    .required(() => CommonValidationTranslations.requiredField())
    .test(
      'isGreaterThenEnd',
      () =>
        t({
          id: 'myTrips.drivingLogValidation.mustBeLessThenEnd',
          message: 'Odometer start must be less then odometer end',
        }),
      function (startKilometers) {
        const { endKilometers } = this.parent;

        if (!startKilometers || !endKilometers) {
          return true;
        }

        return !!(endKilometers && endKilometers >= startKilometers);
      },
    ),
  endKilometers: number()
    .positive(() => CommonValidationTranslations.number.atLeast({ value: 0 }))
    .required(() => CommonValidationTranslations.requiredField())
    .test(
      'isLessThenStart',
      () =>
        t({
          id: 'myTrips.drivingLogValidation.mustBeMoreThenStart',
          message: 'Odometer end must be more then odometer start',
        }),
      function (endKilometers) {
        const { startKilometers } = this.parent;

        if (!startKilometers || !endKilometers) {
          return true;
        }

        return !!(endKilometers && endKilometers >= startKilometers);
      },
    ),
});
