import React, { useEffect, useState, useMemo, Dispatch, SetStateAction } from 'react';
import { Button, Loader, Paragraph } from '@gourban/ui-components';
import { Form, FormikProvider, useFormik } from 'formik';
import { isEmpty, last, trim } from 'lodash';
import DateSelect from '@/features/booking/components/BookingFlow/components/DateSelection/DateSelect';
import CompletionTags from '@/features/booking/components/CompletionTags';
import {
  BookingCreationArgs,
  BookingExtended,
  BookingOverviewForm,
  BookingSteps,
} from '@/features/booking/types';
import OverviewModal from '@/features/booking/components/OverviewModal/OverviewModal';
import VehicleCategorySelection from '@/features/booking/components/BookingFlow/components/VehicleCategorySelection/VehicleCategorySelection';
import VehicleCategorySummary from '@/features/booking/components/BookingFlow/components/VehicleCategorySummary/VehicleCategorySummary';
import AdditionsSelection from '@/features/booking/components/BookingFlow/components/AdditionsSelection/AdditionsSelection';
import BookingSummary from '@/features/booking/components/BookingFlow/components/BookingSummary/BookingSummary';
import BookingCompleted from '@/features/booking/components/BookingFlow/components/BookingCompleted/BookingCompleted';
import { isEqual, isSameDay } from 'date-fns';
import { useTypedSelector } from '@/core/redux/hooks';
import {
  getActiveFilterValues,
  getFilteredStationsData,
} from '@/features/account/redux/account.selectors';
import OverviewModalFooter from '@/features/booking/components/OverviewModal/OverviewModalFooter';
import OverviewModalHeader from '@/features/booking/components/OverviewModal/OverviewModalHeader';
import { useRequestSingleBranchQuery } from '@/features/branches/services/Branches.service';
import { useRequestUserTermsAndConditionsStatusQuery } from '@/features/account/services/Account.service';
import { Trans } from '@lingui/react/macro';
import { CommonValidationTranslations } from '@/core/utils/commonValidationTranslations';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import AccountSelect from '@/features/booking/components/BookingFlow/components/AccountSelection/AccountSelection';
import { useDispatch } from 'react-redux';
import {
  setBookingCreationProcessStarted,
  setClearFiltersRequested,
} from '@/core/features/account/redux/account.reducer';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { useStationTimezone } from '@/features/booking/hooks/useStationTimezone';

export interface BookingFlowIDs {
  bookingId?: string;
  branchId: string;
}

interface CreateOrUpdateBookingT<Steps> extends BookingFlowIDs {
  initialValues: BookingOverviewForm;
  flowSteps: Steps;
  setSteps: Dispatch<SetStateAction<Steps>>;
  onBookingSubmit: (values: BookingCreationArgs) => Promise<BookingExtended>;
  onBookingCompleted: () => void;
  onClose: () => void;
}

const BookingFlow = <Steps extends Partial<BookingSteps>>({
  initialValues,
  flowSteps,
  setSteps,
  bookingId,
  branchId,
  onBookingSubmit,
  onBookingCompleted,
  onClose,
}: CreateOrUpdateBookingT<Steps>) => {
  const activeFilters = useTypedSelector(getActiveFilterValues);
  const { data: termsAndConditions } = useRequestUserTermsAndConditionsStatusQuery(branchId);
  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const dispatch = useDispatch();
  const { data: branchData } = useRequestSingleBranchQuery(branchId, {
    skip: termsAndConditions?.state !== 'ACCEPTED',
  });
  const filteredStations = useTypedSelector(getFilteredStationsData);
  const [upsertedBookingData, setUpsertedBookingData] = useState<BookingExtended | null>(null);
  const stepsKeys = useMemo(
    () => Object.keys(flowSteps) as (keyof Partial<BookingSteps>)[],
    [flowSteps],
  );
  const initialSteps = useMemo(() => {
    return flowSteps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { convertToStationTimezone } = useStationTimezone(
    branchId,
    termsAndConditions?.state !== 'ACCEPTED',
  );

  const vehicleCategoryId = useMemo(() => {
    const URLParams = new URLSearchParams(window.location.search);

    return URLParams.get('vehicleCategoryId') ?? '';
  }, []);

  // Check if station doesnt have available vehicle categories as user requested in filters
  // This is later used to display a message to the user
  const stationsHasNoFilterMatch = useMemo(() => {
    const hasActiveFilters = activeFilters?.startTime ?? !isEmpty(activeFilters?.vehicleAttributes);

    if (!filteredStations || !branchId || !hasActiveFilters) return false;

    return !filteredStations.find((station) => station.branch.id === +branchId)?.availableCategories
      ?.length;
  }, [branchId, filteredStations, activeFilters]);

  const validate = (values: BookingOverviewForm) => {
    const errors: Record<string, string> = {};

    if (!values.bookingDate?.from && !values.bookingDate?.to) {
      errors.bookingDate = CommonValidationTranslations.requiredField();
    }

    if (!values.bookingTime?.from || !values.bookingTime?.to) {
      errors.bookingTime = CommonValidationTranslations.requiredField();
    }

    if (
      stepsKeys.includes('vehicleCategory') &&
      flowSteps.vehicleCategory!?.status === 'active' &&
      !values.selectedVehicleCategory
    ) {
      errors.selectedVehicleCategory = CommonValidationTranslations.requiredField();
    }

    if (
      stepsKeys.includes('vehicleCategorySummary') &&
      flowSteps.vehicleCategorySummary!.status === 'active' &&
      !values.rentalRequirementsFulfilled
    ) {
      errors.rentalRequirementsFulfilled = CommonValidationTranslations.requiredField();
    }

    if (
      stepsKeys.includes('account') &&
      flowSteps.account!.status === 'active' &&
      !values.selectedPaymentMethod &&
      !values.selectedPaymentType
    ) {
      errors.selectedPaymentMethod = CommonValidationTranslations.requiredField();
    }

    if (
      stepsKeys.includes('account') &&
      flowSteps.account!.status === 'active' &&
      values.selectedPaymentType === 'BUSINESS' &&
      bookingSettings?.value.reason?.businessUser === 'required' &&
      !trim(values.reason)
    ) {
      errors.reason = CommonValidationTranslations.requiredField();
    }

    return errors;
  };

  const bookingOverviewForm = useFormik<BookingOverviewForm>({
    initialValues,
    validate,
    validateOnMount: true,
    onSubmit: (values) => {
      const {
        bookingDate,
        bookingTime,
        selectedVehicleCategory: categoryID,
        selectedAdditions: additions,
        selectedPaymentMethod,
        selectedPaymentType,
        reason,
      } = values;

      return onBookingSubmit({
        branchId,
        vehicleCategoryId: categoryID!,
        additions,
        reason: trim(reason),
        rentalType: selectedPaymentType!,
        userGroupCode: selectedPaymentType === 'BUSINESS' ? selectedPaymentMethod! : null,
        startTime: convertToStationTimezone(mergeDateTime(bookingDate!.from!, bookingTime?.from)),
        endTime: convertToStationTimezone(
          mergeDateTime(bookingDate?.to ?? bookingDate!.from!, bookingTime?.to),
        ),
      }).then(setUpsertedBookingData);
    },
  });

  // We need to reset the form everytime ID changes because user needs to go through the process again
  useEffect(() => {
    setSteps(initialSteps);
    bookingOverviewForm.resetForm();
    // flushSync doesn't work inside useEffect, have to wrap with setTimeout
    setTimeout(() => {
      bookingOverviewForm.validateForm();
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  // When ever user changes start date, we need to reset values of some form elements on other fields
  useEffect(() => {
    const resetFormFields = () => {
      bookingOverviewForm.setFieldValue('rentalRequirementsFulfilled', false);
      bookingOverviewForm.setFieldValue('selectedAdditions', undefined);
      if (!initialValues.selectedVehicleCategory) {
        bookingOverviewForm.setFieldValue('selectedVehicleCategory', undefined);
      }
    };

    // If initial values exist, only clear if its different then initialValues
    if (
      bookingOverviewForm.values.bookingDate?.from &&
      bookingOverviewForm.initialValues.bookingDate?.from
    ) {
      if (
        !isEqual(
          bookingOverviewForm.values.bookingDate?.from,
          bookingOverviewForm.initialValues.bookingDate?.from,
        )
      ) {
        resetFormFields();
      }
    } else {
      resetFormFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingOverviewForm.values.bookingDate?.from, bookingOverviewForm.values.bookingDate?.to]);

  useEffect(() => {
    // Abit hacky but no other way to do it, its used to tell the app that current process of booking creation started
    // Mainly used to display a warning message when user tries to change to different station
    const dateFromChanged = !isSameDay(
      bookingOverviewForm.values.bookingDate?.from!,
      bookingOverviewForm.initialValues.bookingDate?.from!,
    );

    const dateToChanged = !isSameDay(
      bookingOverviewForm.values.bookingDate?.to!,
      bookingOverviewForm.initialValues.bookingDate?.to!,
    );

    const shouldStartBookingCreationProcess = bookingId
      ? dateFromChanged || dateToChanged
      : Boolean(bookingOverviewForm.values.bookingDate?.from);

    dispatch(setBookingCreationProcessStarted(shouldStartBookingCreationProcess));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingOverviewForm.values.bookingDate]);

  const activeStep = useMemo(() => {
    return stepsKeys.find((key) => flowSteps[key]?.status === 'active');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsKeys]);

  const isLastStep = useMemo(() => {
    return flowSteps[last(stepsKeys)!]?.status === 'active';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsKeys]);

  const showStep = useMemo(() => {
    if (stepsKeys.includes('date') && activeStep === 'date') {
      return (
        <DateSelect
          vehicleCategoryId={vehicleCategoryId}
          rules={flowSteps.date?.rules}
          bookingId={bookingId}
          branchId={branchId}
        />
      );
    }

    if (stepsKeys.includes('account') && activeStep === 'account') {
      return <AccountSelect bookingId={bookingId} />;
    }

    if (stepsKeys.includes('vehicleCategory') && activeStep === 'vehicleCategory') {
      return <VehicleCategorySelection bookingId={bookingId} branchId={branchId} />;
    }

    if (stepsKeys.includes('vehicleCategorySummary') && activeStep === 'vehicleCategorySummary') {
      return <VehicleCategorySummary branchId={branchId} />;
    }

    if (stepsKeys.includes('extras') && activeStep === 'extras') {
      return <AdditionsSelection<Steps> setSteps={setSteps} branchId={branchId} />;
    }

    if (stepsKeys.includes('summary') && activeStep === 'summary') {
      return <BookingSummary branchId={branchId} />;
    }

    if (stepsKeys.includes('thankYou') && activeStep === 'thankYou') {
      return (
        <BookingCompleted
          onBookingCompleted={onBookingCompleted}
          bookingId={bookingId}
          booking={upsertedBookingData!}
          rules={flowSteps.thankYou?.rules}
        />
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertedBookingData, setSteps, activeStep, stepsKeys, bookingId, branchId, flowSteps]);

  return (
    <FormikProvider value={bookingOverviewForm}>
      <OverviewModal
        onClose={onClose}
        displayFallback={!branchData}
        modalHeader={
          <OverviewModalHeader
            shouldHide={!branchData || flowSteps?.thankYou?.status === 'active'}
            customHeading={
              isLastStep && <Trans id="bookings.createOrUpdate.overview">Booking overview</Trans>
            }
            steps={Object.values(flowSteps)}
          />
        }
        modalFooter={
          flowSteps?.thankYou?.status !== 'active' &&
          !stationsHasNoFilterMatch && (
            <OverviewModalFooter<Steps>
              bookingId={bookingId}
              initialSteps={initialSteps}
              setSteps={setSteps}
              steps={flowSteps}
              shouldHide={!branchData}
            />
          )
        }
      >
        <Form>
          {stationsHasNoFilterMatch && (
            <>
              <CompletionTags branchId={branchId} bookingSteps={flowSteps} />
              <Paragraph weight="medium">
                <Trans id="bookings.createOrUpdate.noFilterMatch">
                  We are sorry, but we couldn't find any vehicles available for this station that
                  match your search criteria.
                </Trans>
              </Paragraph>
              <Button
                onClick={() => {
                  dispatch(setClearFiltersRequested(true));
                  bookingOverviewForm.setFieldValue('bookingDate', undefined);
                }}
                size="small"
              >
                <Trans id="bookings.createOrUpdate.clearFilters">Clear filters</Trans>
              </Button>
            </>
          )}

          {bookingOverviewForm.isSubmitting && <Loader cover />}

          {!bookingOverviewForm.isSubmitting && !stationsHasNoFilterMatch && (
            <>
              {stepsKeys.includes('thankYou') &&
                !isLastStep &&
                flowSteps.thankYou!.status !== 'active' && (
                  <CompletionTags branchId={branchId} bookingSteps={flowSteps} />
                )}
              {showStep}
            </>
          )}
        </Form>
      </OverviewModal>
    </FormikProvider>
  );
};

export default BookingFlow;
