import React, { FC } from 'react';
import { Checkbox, Column, Row } from '@gourban/ui-components';
import ConditionalUrl from '@/core/components/ConditionalUrl';
import { BranchWithTerms } from '@/features/branches/types';
import { Trans } from '@lingui/react/macro';

const AcceptTermsAndConditions: FC<Omit<Partial<BranchWithTerms['termsAndConditions']>, 'id'>> = ({
  termsAndConditionsText = <Trans id="auth.termsAndConditions">terms and conditions</Trans>,
  termsAndConditionsUrl,
  privacyGuidelinesText = <Trans id="auth.privacyPolicy">privacy policy</Trans>,
  privacyGuidelinesUrl,
}) => {
  return (
    <Row marginBottom={8} gapSm="sm">
      <Column sm={12}>
        <Checkbox
          name="terms"
          fieldAttr={{
            id: 'terms',
          }}
          fieldProps={{
            label: (
              <span>
                <Trans id="auth.termsAndConditions.description">
                  I accept the current{' '}
                  <ConditionalUrl url={termsAndConditionsUrl} urlText={termsAndConditionsText} />
                </Trans>
              </span>
            ),
          }}
        />
      </Column>

      <Column sm={12}>
        <Checkbox
          name="privacy"
          fieldAttr={{
            id: 'privacy',
          }}
          fieldProps={{
            label: (
              <span>
                <Trans id="auth.privacyPolicy.description">
                  Please take into account our{' '}
                  <ConditionalUrl url={privacyGuidelinesUrl} urlText={privacyGuidelinesText} />
                </Trans>
              </span>
            ),
          }}
        />
      </Column>
    </Row>
  );
};

export default AcceptTermsAndConditions;
