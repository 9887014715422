import React, { FC } from 'react';
import { basicInfo, payment, verification } from '@/core/pages/Account/routes';
import { useNavigate } from 'react-router';
import {
  useRequestPackageInformationQuery,
  useRequestPaymentSourcesQuery,
} from '@/features/payment/services/Payment.service';
import { useTypedSelector } from '@/core/redux/hooks';
import { getIsStepCompleted, getPromoCode } from '@/features/account/redux/account.selectors';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';
import PaymentStep from '@/features/account/components/Onboarding/PaymentStep';
import { Loader, Button, Paragraph } from '@gourban/ui-components';
import { getPackageInformationWithPromoCode } from '@/features/payment/redux/payment.selectors';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import Banner from '@/core/components/Banner';
import BuyPackage from '@/features/account/components/Onboarding/BuyPackage';
import FullScreen from '@/core/components/Layouts/FullScreen';
import AccountStepper from '@/features/account/components/Onboarding/Stepper';
import OnboardingActionButtons from '@/features/account/components/Onboarding/OnboardingActionButtons';
import ResponsiveFixedButtons from '@/core/components/UI/ResponsiveFixedButtons';

const Payment: FC = () => {
  const navigate = useNavigate();
  const stepComplete = useTypedSelector((state) => getIsStepCompleted(state, 'payment'));
  const promoCode = useTypedSelector(getPromoCode);
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const { isLoading: isLoadingPaymentSourced } = useRequestPaymentSourcesQuery({
    branchId: resolvedBranch!.id,
  });
  const { data: packageInformation, isLoading: loadingPackages } =
    useRequestPackageInformationQuery(resolvedBranch!.id);
  const promoCodePackageInfo = useTypedSelector((state) =>
    getPackageInformationWithPromoCode(state, {
      promotionCode: promoCode!,
      packageCode: packageInformation?.code!,
      branchId: resolvedBranch!.id,
    }),
  );
  const packageInfo = promoCodePackageInfo ?? packageInformation;

  if (loadingPackages || isLoadingPaymentSourced) return <Loader cover />;

  return (
    <FullScreen
      mainSize="full"
      title={
        <AccountStepper
          steps={[
            { stepName: 'basic-info', ...basicInfo },
            { stepName: 'payment', ...payment },
            { stepName: 'verification', ...verification },
          ]}
        />
      }
      actions={
        <ResponsiveFixedButtons>
          <OnboardingActionButtons
            nextButton={
              packageInfo && !stepComplete ? (
                <BuyPackage packageInfo={packageInfo} />
              ) : (
                <Button onClick={() => navigate(verification.path)}>
                  <Trans id="general.next">Next</Trans>
                </Button>
              )
            }
          />
        </ResponsiveFixedButtons>
      }
      main={
        stepComplete && packageInfo ? (
          <Banner
            iconColor="stroke-600"
            icon="checkmark"
            iconColorMode="stroke"
            header={t({ id: 'account.payment.thankYouHeader', message: 'Thank you!' })}
            message={
              <Paragraph>
                <Trans id="account.payment.thankYouMessage">
                  Your order has been successfully placed.
                </Trans>
              </Paragraph>
            }
          />
        ) : (
          <PaymentStep />
        )
      }
    />
  );
};

export default Payment;
