import { FC } from 'react';
import { useTypedSelector } from '@/core/redux/hooks';
import { Navigate, useLocation } from 'react-router';
import { getIsLoggedIn } from '@/core/features/auth/redux/authorization.selectors';
import { AuthGuardT } from '@/features/auth/types/Auth.types';

/**
 * Auth guard is used to redirect user to login page if not authenticated
 */
const AuthGuard: FC<AuthGuardT> = ({ children, fallbackBehavior = 'redirect' }) => {
  const userAuthenticated = useTypedSelector(getIsLoggedIn);
  const location = useLocation();

  if (userAuthenticated) {
    // For some reason if it's not in fragment, AuthGuard component is having typing issues
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return fallbackBehavior === 'ignore' ? null : (
    <Navigate to="/auth/login" state={{ from: location }} />
  );
};

export default AuthGuard;
