import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { useNavigate, NavigateOptions } from 'react-router';
import { Button } from '@gourban/ui-components';
import styles from '@/core/assets/scss/components/StepButton.module.scss';
import { useResponsive } from '@/core/hooks/useResponsive';

interface StepButtonT {
  isCompleted: boolean;
  stepNumber: number;
  label: ReactNode;
  url: string;
  isActive?: boolean;
  disabled: boolean;
  navigateOptions?: NavigateOptions;
}

const StepButton: FC<StepButtonT> = ({
  isCompleted,
  label,
  url,
  isActive,
  stepNumber,
  disabled,
  navigateOptions = {},
}) => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  return (
    <Button
      noPadding
      key={url}
      disabled={disabled}
      variation="link"
      onClick={() => navigate(url, navigateOptions)}
      className={cn([
        styles['step-button'],
        isCompleted && styles['step-button--complete'],
        isActive && styles['step-button--active'],
      ])}
      attributes={{ role: 'link' }}
    >
      <span className={styles['step-button__number']}>{stepNumber}</span>
      {(!isMobile || isActive) && label}
    </Button>
  );
};

export default StepButton;
