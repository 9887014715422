import React, { FC } from 'react';
import { Flex, Paragraph, Svg, Tags, Tooltip } from '@gourban/ui-components';
import { VehicleCategoryResults } from '@/features/vehicles/types';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import styles from '../assets/scss/components/VehicleCategoryTimeslotsTags.module.scss';
import { formatTimeslotLabel } from '@/features/booking/utils/formatTimeslotLabel';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import { NearestMinutes } from 'date-fns';
import { getMyTimezoneId } from '@/core/utils/getMyTimezoneId';

interface VehicleCategoryTimeslotsTagsT {
  timeslots: VehicleCategoryResults['availableTimeSlots'];
  limit?: number;
  timezone: string;
}

const VehicleCategoryTimeslotsTags: FC<VehicleCategoryTimeslotsTagsT> = ({
  timeslots,
  limit = 2,
  timezone = getMyTimezoneId(),
}) => {
  const locale = useDateLocale();
  const { data: bookingSettings } = useRequestBookingSettingQuery();

  if (!timeslots) {
    return null;
  }

  return (
    <Flex gap="sm" wrap>
      {timeslots.slice(0, limit).map((timeslot) => (
        <Tags
          bgColor="bg-50"
          className={styles['timeslot-tags']}
          key={`${timeslot.startTime}-${timeslot.endTime}`}
          size="tiny"
          content={
            <Flex gap="sm" align="center">
              <Svg icon="calendar" width={12} color="icon-400" />
              <Paragraph weight="medium" marginBottom={0} size={5}>
                {formatTimeslotLabel(
                  timeslot,
                  timezone,
                  locale,
                  (bookingSettings?.value?.properties?.timeGap ?? 15) as NearestMinutes,
                )}
              </Paragraph>
            </Flex>
          }
        />
      ))}
      {timeslots.length > limit && (
        <Tooltip
          placement="left"
          content={timeslots.slice(limit, timeslots.length).map((timeslot) => (
            <Paragraph
              textColor="text-0"
              key={`${timeslot.startTime}-${timeslot.endTime}`}
              marginBottom={0}
              size={5}
            >
              {formatTimeslotLabel(
                timeslot,
                timezone,
                locale,
                (bookingSettings?.value?.properties?.timeGap ?? 15) as NearestMinutes,
              )}
            </Paragraph>
          ))}
        >
          <Tags bgColor="bg-50" size="tiny" content={`+${timeslots.length - limit}`} />
        </Tooltip>
      )}
    </Flex>
  );
};

export default VehicleCategoryTimeslotsTags;
