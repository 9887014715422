import React, { useMemo, useEffect, Dispatch, SetStateAction } from 'react';
import { Loader, Paragraph, Row } from '@gourban/ui-components';
import { useFormikContext } from 'formik';
import { BookingOverviewForm, BookingSteps } from '@/features/booking/types';
import { useRequestVehicleAdditionsQuery } from '@/features/vehicles/services/Vehicles.service';
import AdditionCard from './AdditionCard';
import { Trans } from '@lingui/react/macro';
import { BookingFlowIDs } from '@/features/booking/components/BookingFlow/BookingFlow';
import { useRequestBookingEstimatedPricingQuery } from '@/features/booking/services/Booking.service';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import ErrorFallback from '@/core/components/ErrorHandlers/ErrorFallback';

interface AdditionsSelectionT<Steps> extends Omit<BookingFlowIDs, 'bookingId'> {
  setSteps: Dispatch<SetStateAction<Steps>>;
}

const AdditionsSelection = <Steps extends Partial<BookingSteps>>({
  setSteps,
  branchId,
}: AdditionsSelectionT<Steps>) => {
  const { values, setFieldValue } = useFormikContext<BookingOverviewForm>();
  const {
    data: additionsData,
    isFetching: isFetchingAdditions,
    error: isAdditionError,
  } = useRequestVehicleAdditionsQuery({
    branchId,
    categoryId: values.selectedVehicleCategory!,
  });

  const {
    data: estimatedPrice,
    isFetching: isFetchingPriceEstimation,
    error: isPricingEstimationError,
  } = useRequestBookingEstimatedPricingQuery(
    {
      branchId: +branchId,
      startTime: mergeDateTime(values.bookingDate!.from!, values.bookingTime?.from).toISOString(),
      endTime: mergeDateTime(
        values.bookingDate!.to ?? values.bookingDate!.from!,
        values.bookingTime?.to,
      ).toISOString(),
      additions: additionsData?.map((addition) => addition.code)!,
      vehicleCategoryId: values.selectedVehicleCategory!,
    },
    { skip: !additionsData?.length },
  );

  useEffect(() => {
    const selected = additionsData
      ? values.selectedAdditions?.filter((code) => additionsData?.find((add) => add.code === code))
      : values.selectedAdditions;
    void setFieldValue('selectedAdditions', selected);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionsData]);

  const additionsPriceMap = useMemo(() => {
    return (
      estimatedPrice?.additions?.reduce(
        (acc, addition) => {
          acc[addition.code] = addition.price;
          return acc;
        },
        {} as Record<string, number>,
      ) ?? null
    );
  }, [estimatedPrice]);

  const filterOutAdditionsWithNoPricing = useMemo(() => {
    if (!additionsData || !additionsPriceMap) return [];

    return additionsData
      .filter((addition) => !addition.isInternal)
      .filter(
        (addition) => additionsPriceMap?.[addition.code] && additionsPriceMap[addition.code] > 0,
      );
  }, [additionsData, additionsPriceMap]);

  useEffect(() => {
    if (
      !filterOutAdditionsWithNoPricing.length &&
      !isFetchingAdditions &&
      !isFetchingPriceEstimation
    ) {
      setSteps((steps) => ({
        ...steps,
        extras: { ...steps.extras, status: 'completed' },
        summary: { status: 'active' },
      }));
    }
  }, [filterOutAdditionsWithNoPricing, isFetchingAdditions, setSteps, isFetchingPriceEstimation]);

  if (!!isPricingEstimationError || !!isAdditionError) {
    return <ErrorFallback />;
  }

  return (
    <div>
      <Paragraph marginBottom={24} weight="medium">
        <Trans id="bookings.createOrUpdate.extras">Extras</Trans>
      </Paragraph>

      {isFetchingAdditions || isFetchingPriceEstimation ? (
        <Loader cover />
      ) : (
        <Row gapSm="sm">
          {!!filterOutAdditionsWithNoPricing.length &&
            filterOutAdditionsWithNoPricing.map((addition) => (
              <AdditionCard
                key={addition.name + addition.code}
                currency={estimatedPrice!.currency}
                addition={addition}
                estimatedTotal={additionsPriceMap![addition.code]}
              />
            ))}
        </Row>
      )}
    </div>
  );
};

export default AdditionsSelection;
