import { lazy } from 'react';
import {
  businessAccountRoot,
  businessAccountTrips,
  businessAccountTeamRoot,
  businessAccountMembers,
  businessAccountCostCenters,
  businessAccountCompanyRoot,
  businessAccountBilling,
  businessAccountSettings,
  businessAccountCostCenterCreate,
  businessAccountTrip,
  businessAccountCostCentersView,
  businessAccountCreateRoot,
  businessAccountCreateCompanyInfoStep,
  businessAccountCreatePaymentStep,
  businessAccountMember,
  businessAccountMembersInvite,
  businessAccountJoinRequests,
  businessAccountTripDrivingLog,
  businessAccountOnDemandTrips,
  businessAccountOnDemandTrip,
} from './routes';
import { businessAccountJoinApplication } from './routesPublic';
import { Route, RouteStaticConfig } from '@/core/types';
import DashboardLayout from '@/core/components/Layouts/Dashboard';
import { Outlet } from 'react-router';
import ErrorBoundarySuspense from '@/core/components/ErrorHandlers/ErrorBoundarySuspense';
import { Loader } from '@gourban/ui-components';
import SkeletonLoader from '@/core/components/SkeletonLoader';
import CompanyInfo from '@/core/pages/BusinessAccounts/Create/CompanyInfo';
import Payment from '@/core/pages/BusinessAccounts/Create/Payment';
import BusinessAccountAdminRestricted from '@/features/businessAccounts/components/Restrictions/BusinessAccountAdminRestricted';
import CreateBusinessAccount from '@/core/pages/BusinessAccounts/Create/Create';
import CreateBusinessAccountCheck from '@/features/businessAccounts/components/CreateBusinessAccountCheck';

const lazyLoaded = {
  Trips: lazy(() => import('@/core/pages/BusinessAccounts/Trips/Trips')),
  TripDetails: lazy(() => import('@/core/pages/BusinessAccounts/Trips/TripDetails')),
  TripDrivingLog: lazy(() => import('@/core/pages/BusinessAccounts/Trips/TripDrivingLog')),
  Members: lazy(() => import('@/core/pages/BusinessAccounts/Members/Members')),
  MemberSingle: lazy(() => import('@/core/pages/BusinessAccounts/Members/MemberSingle')),
  MemberInvite: lazy(() => import('@/core/pages/BusinessAccounts/Members/MemberInvite')),
  CostCenters: lazy(() => import('@/core/pages/BusinessAccounts/CostCenters/CostCenters')),
  CostCenterCreate: lazy(
    () => import('@/core/pages/BusinessAccounts/CostCenters/CostCenterCreate'),
  ),
  CostCenterSingle: lazy(
    () => import('@/core/pages/BusinessAccounts/CostCenters/CostCenterSingle'),
  ),
  Settings: lazy(() => import('@/core/pages/BusinessAccounts/Settings/Settings')),
  Billing: lazy(() => import('@/core/pages/BusinessAccounts/Billing/Billing')),
  JoinRequests: lazy(() => import('@/core/pages/BusinessAccounts/JoinRequests/JoinRequests')),
  BusinessAccountApplication: lazy(
    () => import('@/core/pages/BusinessAccounts/JoinRequests/JoinApplication'),
  ),
  OnDemandTrips: lazy(() => import('@/core/pages/BusinessAccounts/Trips/OnDemandTrips')),
  OnDemandTripDetails: lazy(
    () => import('@/core/pages/BusinessAccounts/Trips/OnDemandTripDetails'),
  ),
};

export const businessAccountTeamSubroutes: RouteStaticConfig[] = [
  businessAccountMembers,
  businessAccountJoinRequests,
];

export const businessAccountCompanySubroutes: RouteStaticConfig[] = [
  businessAccountBilling,
  businessAccountSettings,
];

const businessAccountCreate: Route = {
  ...businessAccountCreateRoot,
  element: <CreateBusinessAccountCheck />,
  routes: [
    {
      ...businessAccountCreateRoot,
      element: <CreateBusinessAccount />,
    },
    {
      ...businessAccountCreateCompanyInfoStep,
      element: <CompanyInfo />,
    },
    {
      ...businessAccountCreatePaymentStep,
      element: <Payment />,
    },
  ],
};

const businessAccountApplication: Route = {
  ...businessAccountJoinApplication,
  element: (
    <ErrorBoundarySuspense fallback={<Loader cover fixed />}>
      <lazyLoaded.BusinessAccountApplication />
    </ErrorBoundarySuspense>
  ),
};

const businessAccountRoutes: Route = {
  ...businessAccountRoot,
  element: (
    <BusinessAccountAdminRestricted>
      <DashboardLayout />
    </BusinessAccountAdminRestricted>
  ),
  routes: [
    {
      ...businessAccountTrips,
      element: (
        <ErrorBoundarySuspense fallback={<SkeletonLoader withContentHeader />}>
          <lazyLoaded.Trips />
        </ErrorBoundarySuspense>
      ),
      routes: [
        {
          ...businessAccountTrip,
          element: (
            <ErrorBoundarySuspense fallback={<Loader cover />}>
              <lazyLoaded.TripDetails />
            </ErrorBoundarySuspense>
          ),
        },
        {
          ...businessAccountTripDrivingLog,
          element: (
            <ErrorBoundarySuspense fallback={<Loader cover />}>
              <lazyLoaded.TripDrivingLog />
            </ErrorBoundarySuspense>
          ),
        },
      ],
    },
    {
      ...businessAccountOnDemandTrips,
      element: (
        <ErrorBoundarySuspense fallback={<Loader cover />}>
          <lazyLoaded.OnDemandTrips />
        </ErrorBoundarySuspense>
      ),
      routes: [
        {
          ...businessAccountOnDemandTrip,
          element: (
            <ErrorBoundarySuspense fallback={<Loader cover />}>
              <lazyLoaded.OnDemandTripDetails />
            </ErrorBoundarySuspense>
          ),
        },
      ],
    },
    {
      ...businessAccountCostCenters,
      element: (
        <ErrorBoundarySuspense fallback={<SkeletonLoader withContentHeader />}>
          <lazyLoaded.CostCenters />
        </ErrorBoundarySuspense>
      ),
      routes: [
        {
          ...businessAccountCostCenterCreate,
          element: (
            <ErrorBoundarySuspense fallback={<Loader cover />}>
              <lazyLoaded.CostCenterCreate />
            </ErrorBoundarySuspense>
          ),
        },
      ],
    },
    {
      ...businessAccountCostCentersView,
      element: (
        <ErrorBoundarySuspense fallback={<Loader zIndex={1000} fixed cover />}>
          <lazyLoaded.CostCenterSingle />
        </ErrorBoundarySuspense>
      ),
    },
    {
      ...businessAccountTeamRoot,
      element: <Outlet />,
      routes: [
        {
          ...businessAccountMembers,
          element: (
            <ErrorBoundarySuspense fallback={<SkeletonLoader withContentHeader />}>
              <lazyLoaded.Members />
            </ErrorBoundarySuspense>
          ),
          routes: [
            {
              ...businessAccountMembersInvite,
              element: (
                <ErrorBoundarySuspense fallback={<Loader zIndex={1000} fixed cover />}>
                  <lazyLoaded.MemberInvite />
                </ErrorBoundarySuspense>
              ),
            },
          ],
        },
        {
          ...businessAccountMember,
          element: (
            <ErrorBoundarySuspense fallback={<Loader zIndex={1000} fixed cover />}>
              <lazyLoaded.MemberSingle />
            </ErrorBoundarySuspense>
          ),
        },
        {
          ...businessAccountJoinRequests,
          element: (
            <ErrorBoundarySuspense fallback={<SkeletonLoader withContentHeader />}>
              <lazyLoaded.JoinRequests />
            </ErrorBoundarySuspense>
          ),
        },
      ],
    },
    {
      ...businessAccountCompanyRoot,
      element: <Outlet />,
      routes: [
        {
          ...businessAccountBilling,
          element: (
            <ErrorBoundarySuspense fallback={<SkeletonLoader withContentHeader />}>
              <lazyLoaded.Billing />
            </ErrorBoundarySuspense>
          ),
        },
        {
          ...businessAccountSettings,
          element: (
            <ErrorBoundarySuspense fallback={<SkeletonLoader withContentHeader />}>
              <lazyLoaded.Settings />
            </ErrorBoundarySuspense>
          ),
        },
      ],
    },
  ],
};

export { businessAccountApplication };

export default [businessAccountRoutes, businessAccountCreate];
