import React, { Dispatch, SetStateAction } from 'react';
import { Button, Column, Row } from '@gourban/ui-components';
import { useFormikContext } from 'formik';
import {
  BookingOverviewForm,
  BookingStep,
  BookingStepKeys,
  BookingSteps,
} from '@/features/booking/types';
import { flushSync } from 'react-dom';
import { Trans } from '@lingui/react/macro';
import { BookingFlowIDs } from '@/features/booking/components/BookingFlow/BookingFlow';

export interface OverviewModalFooterT<Steps> extends Pick<BookingFlowIDs, 'bookingId'> {
  steps: Steps;
  shouldHide: boolean;
  setSteps: Dispatch<SetStateAction<Steps>>;
  initialSteps: Steps;
}

export interface BookingStepWithRules extends BookingStep {
  rules?: Record<string, any>;
}

interface ActiveStepExtendedData extends BookingStepWithRules {
  key: string;
  index: number;
}

const OverviewModalFooter = <Steps extends Partial<BookingSteps>>({
  initialSteps,
  steps,
  setSteps,
  shouldHide,
  bookingId,
}: OverviewModalFooterT<Steps>) => {
  const { setFieldValue, values, isValid, validateForm, submitForm, resetForm } =
    useFormikContext<BookingOverviewForm>();

  if (shouldHide) {
    return null;
  }

  const getCurrentStepData = () => {
    return Object.entries<BookingStepWithRules>({ ...steps }).reduce((activeStep, [key, step]) => {
      if (step.status === 'active') {
        activeStep = {
          ...step,
          key,
          index: Object.keys(initialSteps).findIndex((s) => s === key),
        };
      }

      return activeStep;
    }, {} as ActiveStepExtendedData);
  };

  const handleStepBack = () => {
    const currentActiveStep = getCurrentStepData();

    const nextActiveStepKey = Object.keys(initialSteps)[
      currentActiveStep.index - 1
    ] as Partial<BookingStepKeys>;

    if (nextActiveStepKey) {
      flushSync(() => {
        setSteps({
          ...steps,
          [currentActiveStep.key]: { ...currentActiveStep, status: 'inactive' },
          [nextActiveStepKey]: {
            ...initialSteps[nextActiveStepKey],
            status: 'active',
          },
        });
      });

      void validateForm();
    }
  };

  const openNextStep = async () => {
    if (steps?.summary?.status !== 'active') {
      const currentActiveStep = getCurrentStepData();

      const nextActiveStepKey = Object.keys(initialSteps)[
        currentActiveStep.index + 1
      ] as BookingStepKeys;

      if (nextActiveStepKey) {
        flushSync(() => {
          setSteps({
            ...steps,
            [currentActiveStep.key]: {
              ...currentActiveStep,
              status: 'completed',
            },
            [nextActiveStepKey]: {
              ...initialSteps[nextActiveStepKey],
              status: 'active',
            },
          });
        });

        void validateForm();
      }
    } else {
      void submitForm();
    }
  };

  let actionButton;
  if (steps?.summary?.status === 'active') {
    if (bookingId) {
      actionButton = <Trans id="bookings.update">Update</Trans>;
    } else {
      actionButton = <Trans id="bookings.book">Book</Trans>;
    }
  } else {
    actionButton = <Trans id="bookings.createOrUpdate.nextStep">Next step</Trans>;
  }

  return (
    <Row justify="space-between">
      <Column>
        {steps?.date?.status === 'active' && !steps?.date?.rules?.hideResetButton && (
          <Button
            onClick={() => {
              flushSync(() => {
                const currentSelectedMonth = values.selectedMonth;
                resetForm();
                void setFieldValue('selectedMonth', currentSelectedMonth);
              });

              void validateForm();
            }}
            variation="secondary"
          >
            <Trans id="bookings.createOrUpdate.reset">Reset</Trans>
          </Button>
        )}

        {steps?.date?.status !== 'active' && steps?.summary?.status !== 'active' && (
          <Button onClick={() => handleStepBack()} variation="secondary">
            <Trans id="navigation.goBack">Go back</Trans>
          </Button>
        )}

        {steps?.summary?.status === 'active' && (
          <Button
            onClick={() => {
              flushSync(() => {
                setSteps(initialSteps);
              });
            }}
            variation="secondary"
          >
            <Trans id="general.edit">Edit</Trans>
          </Button>
        )}
      </Column>
      <Column>
        <Button onClick={() => openNextStep()} disabled={!isValid}>
          {actionButton}
        </Button>
      </Column>
    </Row>
  );
};

export default OverviewModalFooter;
