import { useRequestUserRequiredFieldsQuery } from '@/core/services/SettingsManagement.service';
import {
  UserRequiredFieldsProfile,
  FormConfig,
  UserRequiredFieldsSettingsValue,
  UserData,
} from '@/features/auth/types/User.types';
import { object, string, StringSchema, ObjectSchema } from 'yup';
import { CommonValidationTranslations } from '@/core/utils/commonValidationTranslations';
import { t } from '@lingui/core/macro';
import { useRequestMeDataQuery } from '../../account/services/Account.service';

const resolveProfile = (
  profile: UserRequiredFieldsProfile | undefined,
): UserRequiredFieldsProfile => {
  if (profile) {
    return profile;
  }

  const defaultFormConfig = {
    visible: false,
    required: false,
    editable: false,
  };

  return {
    email: defaultFormConfig,
    firstName: defaultFormConfig,
    lastName: defaultFormConfig,
    birthdate: defaultFormConfig,
    phone: defaultFormConfig,
    postcode: defaultFormConfig,
    street: defaultFormConfig,
    city: defaultFormConfig,
    country: defaultFormConfig,
  };
};

const generateStringSchema = (config: FormConfig): StringSchema => {
  const { required } = config;

  let schema = string().trim();

  if (required) {
    schema = schema.required(() => CommonValidationTranslations.requiredField());
  }

  return schema;
};

export const useProfileSchema = (): {
  profile: UserRequiredFieldsProfile;
  validationSchema: ObjectSchema<any>;
  isFetching: boolean;
  isValid: (meData: UserData) => boolean;
} => {
  const { data, isFetching } = useRequestUserRequiredFieldsQuery();
  const { data: userData } = useRequestMeDataQuery();

  const configs: (keyof UserRequiredFieldsProfile)[] = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'birthdate',
    'street',
    'city',
    'postcode',
    'country',
  ];

  const shape = {} as Record<keyof UserRequiredFieldsSettingsValue['profile'], StringSchema>;

  const profile = resolveProfile(data?.value?.profile);

  configs.forEach((config) => {
    const { visible } = profile[config];
    if (visible) {
      shape[config] = generateStringSchema(profile[config]);
    }
  });

  if (shape.email) {
    shape.email = shape.email.email(() =>
      CommonValidationTranslations.invalidField(
        t({
          id: 'auth.forms.email',
          message: 'E-mail',
        }),
      ),
    );
  }

  if (shape.street) {
    if (!userData?.street || profile.street.editable) {
      const addressPattern = /^(?=.*\d)(?=.*[a-zA-Z])(?!^\d+$).+$/;

      shape.street = shape.street.matches(addressPattern, {
        message: CommonValidationTranslations.invalidAddress(),
      });
    }
  }

  const validationSchema = object().shape(shape);

  const isValid = (meData: UserData) => {
    try {
      validationSchema.validateSync(meData); // Throws if invalid
      return true;
    } catch (err) {
      return false;
    }
  };

  return { profile, validationSchema, isFetching, isValid };
};
