import React, { ReactNode, useMemo } from 'react';
import { useTypedSelector } from '@/core/redux/hooks';
import { getMeData } from '@/features/account/redux/account.selectors';
import { Avatar, Column, IconName, Loader, Paragraph, Row, Svg } from '@gourban/ui-components';
import { getInitials } from '@/core/utils/getInitials';
import LangPicker from '@/features/auth/components/LangPicker';
import { NavLink } from 'react-router';
import layoutAsideStyles from '@/features/account/assets/Edit/LayoutAside.module.scss';
import { Trans } from '@lingui/react/macro';
import { useRequestUserUpdateMutation } from '@/features/account/services/Account.service';
import { AvailableLocales, SettingsKeys } from '@/core/enums';
import { values } from 'lodash';
import { addToArrayConditionally } from '@/core/utils/addToArrayConditionally';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { NewsletterSettings } from '@/features/booking/types';

interface AccountEditNavigationLink {
  path: string;
  icon: IconName;
  label: ReactNode;
}

const LayoutAside = () => {
  const meData = useTypedSelector(getMeData);
  const [updateUser, { isLoading }] = useRequestUserUpdateMutation();
  const newsLetters = useTypedSelector((state) =>
    getSetting<NewsletterSettings>(state, SettingsKeys.NEWSLETTER),
  );

  const hasAnyNewsLetter = useMemo(() => {
    if (!newsLetters) {
      return false;
    }

    return values(newsLetters).some((newsLetter) => newsLetter.enabled);
  }, [newsLetters]);

  const navigationLinks: AccountEditNavigationLink[] = [
    {
      path: '/account/edit/basic-info',
      icon: 'infoFilled',
      label: <Trans id="accountEdit.layoutAside.basicInfo">Basic info</Trans>,
    },
    {
      path: '/account/edit/payment',
      icon: 'card',
      label: <Trans id="accountEdit.layoutAside.paymentMethod">Payment method</Trans>,
    },
    {
      path: '/account/edit/business',
      icon: 'buildings',
      label: <Trans id="accountEdit.layoutAside.businessAccount">Business account</Trans>,
    },
    ...addToArrayConditionally<AccountEditNavigationLink>(hasAnyNewsLetter, {
      path: '/account/edit/newsletter',
      icon: 'mailFilled',
      label: <Trans id="accountEdit.layoutAside.newsletter">Newsletter</Trans>,
    }),
    {
      path: '/account/edit/verification',
      icon: 'checkmarkFilled',
      label: <Trans id="accountEdit.layoutAside.verification">Verification</Trans>,
    },
  ];

  return (
    <>
      {isLoading && <Loader cover zIndex={10} />}
      <Row column>
        <Column marginBottom={16}>
          <Row alignItems="center" justify="space-between">
            <Column>
              <Avatar
                textColor="text-0"
                image={meData?.customProperties?.avatar}
                fallback={getInitials(`${meData?.firstName} ${meData?.lastName}`)}
              />
            </Column>
            <Column>
              <LangPicker
                onLangSelected={(lang: AvailableLocales) =>
                  updateUser({
                    language: lang,
                    customProperties: { ...(meData?.customProperties ?? {}), language: lang },
                  })
                }
              />
            </Column>
          </Row>
        </Column>
        <Column className={layoutAsideStyles['layout-aside__user']} sm={12}>
          <Paragraph size={2} weight="medium">
            {meData?.firstName} {meData?.lastName}
          </Paragraph>
        </Column>

        <Column className={layoutAsideStyles['layout-aside__navigation-wrapper']}>
          <Row className={layoutAsideStyles['layout-aside__navigation']} column>
            {navigationLinks.map(({ path, label, icon }) => (
              <NavLink
                key={path + icon}
                className={({ isActive }) =>
                  `${layoutAsideStyles['layout-aside__navigation-item']} ${
                    isActive && layoutAsideStyles['layout-aside__navigation-item--active']
                  }`
                }
                to={path}
              >
                <Svg color="icon-300" width={20} icon={icon} />
                {label}
              </NavLink>
            ))}
          </Row>
        </Column>
      </Row>
    </>
  );
};

export default LayoutAside;
