import React, { useEffect } from 'react';
import TermsAndConditions from '@/features/booking/components/BookingFlow/TermsAndConditions';
import BookingFlow from '@/features/booking/components/BookingFlow/BookingFlow';
import { useNavigate, useParams, useSearchParams } from 'react-router';
import { useRequestUserTermsAndConditionsStatusQuery } from '@/features/account/services/Account.service';
import { Loader, useMap } from '@gourban/ui-components';
import { useBookingPreparation } from '@/features/booking/hooks/useBookingPreparation';
import OverviewModal from '@/core/features/booking/components/OverviewModal/OverviewModal';
import { useGoBack } from '@/core/hooks/useGoBack';
import { useBookingFlowSteps } from '@/core/features/booking/hooks/useBookingFlowSteps';
import { BookingCreationArgs, BookingStepKeys, BookingSteps } from '@/features/booking/types';
import {
  useRequestBookingCreateMutation,
  useRequestBookingUpdateMutation,
} from '@/features/booking/services/Booking.service';
import { addToArrayConditionally } from '@/core/utils/addToArrayConditionally';

const BookingOverview = () => {
  const { branchId, bookingId } = useParams();
  const [searchParams] = useSearchParams();
  const { steps, setSteps } = useBookingFlowSteps<BookingSteps>([
    'date',
    'account',
    ...addToArrayConditionally<BookingStepKeys>(
      !searchParams.get('vehicleCategoryId'),
      'vehicleCategory',
    ),
    'vehicleCategorySummary',
    'extras',
    'summary',
    'thankYou',
  ]);

  const navigate = useNavigate();
  const goBack = useGoBack();
  const mapInstance = useMap();
  const {
    data: termsAndConditions,
    isFetching,
    isError: errorFetchingTermsAndConditions,
  } = useRequestUserTermsAndConditionsStatusQuery(branchId!);
  const { isFetchingBookingData, bookingInitialValues, isError, bookingData } =
    useBookingPreparation(branchId, bookingId, termsAndConditions?.state);
  const [createBooking] = useRequestBookingCreateMutation();
  const [updateBooking] = useRequestBookingUpdateMutation();

  const onSubmitBooking = async (values: BookingCreationArgs) => {
    let booking;

    if (bookingId) {
      booking = await updateBooking({ id: bookingId, ...values }).unwrap();
    } else {
      booking = await createBooking(values).unwrap();
    }

    setSteps((prev) => ({
      ...prev,
      summary: { status: 'completed' },
      thankYou: { status: 'active' },
    }));

    return Promise.resolve(booking);
  };

  useEffect(() => {
    const map = mapInstance.current;

    if (!map) {
      return undefined;
    }

    map.getMap().dragPan.disable();
    map.getMap().scrollZoom.disable();
    map.getMap().keyboard.disable();
    map.getMap().doubleClickZoom.disable();

    return () => {
      map.getMap().dragPan.enable();
      map.getMap().scrollZoom.enable();
      map.getMap().keyboard.enable();
      map.getMap().doubleClickZoom.enable();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookingData && bookingData.branchId !== +branchId! && bookingId) {
      navigate(`/booking/${bookingData.branchId}/edit/${bookingId}`);
    }

    if (isError) {
      navigate('/booking');
    }
  }, [bookingData, bookingId, branchId, navigate, isError]);

  if (isError || errorFetchingTermsAndConditions) {
    return <OverviewModal onClose={goBack} modalHeader={<span />} displayFallback />;
  }

  if (isFetching || isFetchingBookingData) {
    return <Loader cover />;
  }

  if (!termsAndConditions) {
    return null;
  }

  if (termsAndConditions.state === 'NOT_ACCEPTED') {
    return <TermsAndConditions />;
  }

  return (
    <BookingFlow<BookingSteps>
      onClose={() => {
        const params = new URLSearchParams(window.location.search);
        params.delete('vehicleCategoryId');

        navigate({ pathname: '..', search: params.toString() });
      }}
      onBookingSubmit={onSubmitBooking}
      onBookingCompleted={() => navigate('/')}
      branchId={String(branchId)}
      bookingId={bookingId && String(bookingId)}
      flowSteps={steps}
      setSteps={setSteps}
      initialValues={bookingInitialValues}
    />
  );
};

export default BookingOverview;
