import { FC } from 'react';
import accessDeniedModules from '@/features/auth/assets/scss/AccessDenied.module.scss';
import { InsufficientPermissions } from '@/features/auth/types/Auth.types';
import { Trans } from '@lingui/react/macro';

const AccessDenied: FC<InsufficientPermissions> = ({
  title = <Trans id="authorization.accessDenied.title">Access denied.</Trans>,
  description = (
    <Trans id="authorization.accessDenied.description">
      Sorry, you are not allowed to do this.
    </Trans>
  ),
}) => {
  return (
    <div className={accessDeniedModules['access-denied']}>
      <div className={accessDeniedModules['access-denied__content']}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default AccessDenied;
