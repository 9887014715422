import React, { FC, useEffect, useState } from 'react';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import { Button, NotificationStore } from '@gourban/ui-components';
import { setStepComplete } from '@/features/account/redux/account.reducer';
import { AccountService } from '@/features/account/services/Account.service';
import { AccountCacheTags } from '@/features/account/enums';
import { useRequestBuyPackageMutation } from '@/features/payment/services/Payment.service';
import { useLingui } from '@lingui/react';
import { PackageInfo } from '@/features/account/types';
import { useTypedDispatch, useTypedSelector } from '@/core/redux/hooks';
import {
  getIsStepCompleted,
  getPromoCode,
  getPaymentProvider,
  getSelectedCardId,
} from '@/features/account/redux/account.selectors';
import { useSearchParams } from 'react-router';
import StripePaymentHooks from '../../../payment/components/StripePaymentHooks';

interface BuyPackageT {
  packageInfo: PackageInfo;
}

const BuyPackage: FC<BuyPackageT> = ({ packageInfo }) => {
  const [buyPackage, { isLoading: purchasing }] = useRequestBuyPackageMutation();
  const [searchParams] = useSearchParams();
  const dispatch = useTypedDispatch();
  const stepComplete = useTypedSelector((state) => getIsStepCompleted(state, 'payment'));
  const paymentProvider = useTypedSelector(getPaymentProvider);
  const { i18n } = useLingui();
  const [stripeSdk, setStripeSdk] = useState<string | null>(null);
  const promoCode = useTypedSelector(getPromoCode);
  const selectedCardID = useTypedSelector(getSelectedCardId);

  useEffect(() => {
    if (searchParams.has('success') && searchParams.get('success') !== 'true') {
      NotificationStore.addNotification({
        type: 'error',
        title: t({ id: 'account.payment.notificationTitle', message: 'Payment failed' }),
        content: t({
          id: 'account.payment.notificationContent',
          message: 'We failed to complete the purchase.',
        }),
      });
    }
  }, [searchParams]);

  const onBuyPackage = () => {
    buyPackage({
      branchId: packageInfo.branchId,
      packageCode: packageInfo.code,
      promotionCode: promoCode ?? undefined,
    })
      .unwrap()
      .then((res) => {
        if (paymentProvider === 'STRIPE') {
          /* If response exists, we have to make sure users do additional step.
           * Either we redirect them to returned redirectUrl in case when
           * type === 'redirect_to_url' so they finish payment process on that URL
           * or we display modal with iframe which we point to the returned URL
           * which is stored in stripe_js property.
           */
          if (res) {
            if (res.next_action.type === 'redirect_to_url') {
              window.location.href = res.next_action.redirectToUrl.url;
            } else {
              setStripeSdk(res.next_action.useStripeSdk.stripe_js);
            }
            return;
          }
        }

        dispatch(setStepComplete({ name: 'payment', isComplete: true }));
        dispatch(AccountService.util.invalidateTags([AccountCacheTags.REQUEST_ME_DATA]));
      });
  };

  return (
    <>
      {paymentProvider === 'STRIPE' && (
        <StripePaymentHooks clearStripeSdk={() => setStripeSdk(null)} stripeSdk={stripeSdk} />
      )}

      <Button disabled={!selectedCardID} loading={purchasing || !!stripeSdk} onClick={onBuyPackage}>
        {!stepComplete ? (
          `${t({
            id: 'account.payment.pay',
            message: 'Pay',
          })} ${i18n.number(
            packageInfo?.discountedPriceDetails?.gross ??
              packageInfo.discountedPriceDetails?.gross ??
              packageInfo.priceDetails.gross,
            { style: 'currency', currency: 'EUR' },
          )}`
        ) : (
          <Trans id="general.next">Next</Trans>
        )}
      </Button>
    </>
  );
};

export default BuyPackage;
