/**
 * Safely parse a JSON string. If parsing fails, return a default value (null by default).
 *
 * @param {string} jsonString The JSON string to parse.
 * @param {any} defaultValue The value to return if parsing fails. Defaults to null.
 * @returns {any} The parsed object or the default value if parsing fails.
 */
export const tryJSONParse = (jsonString: string, defaultValue: any = null): any => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    console.error('JSON parsing failed', e);
    return defaultValue;
  }
};
