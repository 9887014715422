import { createApi } from '@reduxjs/toolkit/query/react';
import { RestClientWrapper } from '@/core/api/restClientWrapper';
import {
  BillingSummary,
  BusinessAccount,
  BusinessAccountCreateForm,
  BusinessAccountOnDemandTrip,
  BusinessAccountOnDemandTripDetails,
  BusinessAccountOnDemandTripDetailsArgs,
  BusinessAccountOnDemandTripsArgs,
  BusinessAccountTripDetails,
  BusinessAccountTrips,
  BusinessAccountTripsArgs,
  BusinessAccountTripsSingleArgs,
  CostCenter,
  CostCentersArgs,
  JoinRequest,
  JoinRequestArgs,
  JoinRequestMembership,
  JoinRequestStates,
  Member,
  MemberCreate,
  MembersArgs,
  MemberSingleArgs,
} from '../types';
import { TransformedResponse } from '@/core/types';
import { formatResponse } from '@/core/api/formatAPIResponse';
import { prepareDataViewsQueryStrings } from '@/core/utils/parseQueryString';
import { BusinessAccountCacheTags } from '@/features/businessAccounts/enums';
import { NotificationStore } from '@gourban/ui-components';
import { omit } from 'lodash';
import { BusinessAccountSettingsForm } from '@/features/businessAccounts/components/Settings/SettingsForm';
import { t } from '@lingui/core/macro';
import { nullEmptyStrings } from '@/core/utils/nullEmptyStrings';

export const BusinessAccountService = createApi({
  reducerPath: 'api-user-groups',
  baseQuery: RestClientWrapper,
  tagTypes: [
    BusinessAccountCacheTags.COST_CENTERS,
    BusinessAccountCacheTags.COST_CENTER,
    BusinessAccountCacheTags.MEMBER,
    BusinessAccountCacheTags.MEMBERS,
    BusinessAccountCacheTags.BUSINESS_ACCOUNT,
    BusinessAccountCacheTags.BUSINESS_ACCOUNT_MEMBERSHIP,
    BusinessAccountCacheTags.JOIN_REQUESTS,
    BusinessAccountCacheTags.JOIN_REQUESTS_MEMBERSHIP,
  ],
  endpoints: (builder) => ({
    requestBusinessAccount: builder.query<BusinessAccount, void>({
      query: () => ({
        url: '/front/groups-management',
      }),
      keepUnusedDataFor: 60 * 60,
      transformResponse: (data: BusinessAccount[]) => data?.[0],
      providesTags: (data) => [
        { type: BusinessAccountCacheTags.BUSINESS_ACCOUNT, id: data?.id },
        BusinessAccountCacheTags.BUSINESS_ACCOUNT,
      ],
    }),

    requestBusinessAccountMembership: builder.query<Pick<BusinessAccount, 'code' | 'name'>[], void>(
      {
        query: () => ({
          url: '/front/groups',
        }),
        keepUnusedDataFor: 60 * 60,
        providesTags: [BusinessAccountCacheTags.BUSINESS_ACCOUNT_MEMBERSHIP],
      },
    ),

    requestCreateBusinessAccount: builder.mutation<BusinessAccount, BusinessAccountCreateForm>({
      query: (data) => ({
        url: `/front/groups-management`,
        requestParams: {
          method: 'post',
          data: nullEmptyStrings<BusinessAccountCreateForm>(data, ['companyRegistrationNumber']),
        },
        config: {
          onRequestSuccess: () => {
            NotificationStore.addNotification({
              type: 'success',
              title: t({ id: 'general.notification.success', message: 'Success' }),
              content: t({
                id: 'businessAccounts.notification.businessAccountCreateSuccess',
                message: 'Business account created successfully.',
              }),
            });
          },
        },
      }),
      invalidatesTags: [
        BusinessAccountCacheTags.BUSINESS_ACCOUNT,
        BusinessAccountCacheTags.BUSINESS_ACCOUNT_MEMBERSHIP,
      ],
    }),

    requestUpdateBusinessAccount: builder.mutation<
      BusinessAccount,
      BusinessAccountSettingsForm & { id: number }
    >({
      query: (data) => ({
        url: `/front/groups-management/${data.id}`,
        requestParams: {
          method: 'patch',
          data: omit(data, 'id'),
        },
        config: {
          onRequestSuccess: () => {
            NotificationStore.addNotification({
              type: 'success',
              title: t({ id: 'general.notification.success', message: 'Success' }),
              content: t({
                id: 'businessAccounts.notification.businessAccountUpdatedSuccess',
                message: 'Business account updated successfully.',
              }),
            });
          },
        },
      }),
      invalidatesTags: (data) => [
        { type: BusinessAccountCacheTags.BUSINESS_ACCOUNT, id: data?.id },
      ],
    }),

    requestSingleCostCenter: builder.query<CostCenter, string>({
      query: (id) => {
        return {
          url: `/front/cost-centers/${id}`,
        };
      },
      providesTags: [BusinessAccountCacheTags.COST_CENTER],
    }),

    requestCostCenters: builder.query<TransformedResponse<CostCenter[]>, CostCentersArgs>({
      query: (args) => {
        const queryString = prepareDataViewsQueryStrings({ args });

        return {
          url: `/front/cost-centers?${queryString}`,
        };
      },
      transformResponse: formatResponse,
      providesTags: [BusinessAccountCacheTags.COST_CENTERS],
    }),

    requestCostCenterCreation: builder.mutation<void, Omit<CostCenter, 'id'>>({
      query: (data) => ({
        url: '/front/cost-centers',
        requestParams: {
          method: 'post',
          data: nullEmptyStrings<Omit<CostCenter, 'id'>>(data, ['contactEmail']),
        },
        config: {
          onRequestSuccess: () => {
            NotificationStore.addNotification({
              type: 'success',
              title: t({ id: 'general.notification.success', message: 'Success' }),
              content: t({
                id: 'businessAccounts.notification.costCentersCreateSuccess',
                message: 'Cost center created successfully.',
              }),
            });
          },
        },
      }),
      invalidatesTags: [BusinessAccountCacheTags.COST_CENTERS],
    }),

    requestCostCenterUpdate: builder.mutation<CostCenter, Omit<Partial<CostCenter>, 'userGroupId'>>(
      {
        query: (data) => ({
          url: `/front/cost-centers/${data.id}`,
          requestParams: {
            method: 'patch',
            data: omit(data, ['id']),
          },
          config: {
            onRequestSuccess: () => {
              NotificationStore.addNotification({
                type: 'success',
                title: t({ id: 'general.notification.success', message: 'Success' }),
                content: t({
                  id: 'businessAccounts.notification.costCentersUpdateSuccess',
                  message: 'Cost center updated successfully.',
                }),
              });
            },
          },
        }),
        invalidatesTags: () => [
          BusinessAccountCacheTags.COST_CENTER,
          BusinessAccountCacheTags.COST_CENTERS,
        ],
      },
    ),

    requestBusinessAccountTrips: builder.query<
      TransformedResponse<BusinessAccountTrips[]>,
      BusinessAccountTripsArgs
    >({
      query: (args) => {
        const queryString = prepareDataViewsQueryStrings({
          args,
          additionalFieldsToOmit: ['userGroupId'],
        });

        return {
          url: `/front/bookings/user-group/${args.userGroupId}?${queryString}`,
        };
      },
      transformResponse: formatResponse,
    }),

    requestBusinessAccountOnDemandTrips: builder.query<
      TransformedResponse<BusinessAccountOnDemandTrip[]>,
      BusinessAccountOnDemandTripsArgs
    >({
      query: (args) => {
        const queryString = prepareDataViewsQueryStrings({
          args,
          additionalFieldsToOmit: ['userGroupId'],
        });

        return {
          url: `/front/rentals/user-group/${args.userGroupId}?${queryString}`,
        };
      },
      transformResponse: formatResponse,
    }),

    requestBusinessAccountOnDemandTripsById: builder.query<
      BusinessAccountOnDemandTripDetails,
      BusinessAccountOnDemandTripDetailsArgs
    >({
      query: ({ id, userGroupId }) => {
        return {
          url: `/front/rentals/${id}`,
          requestParams: {
            params: {
              userGroupId,
            },
          },
        };
      },
    }),

    requestJoinRequests: builder.query<TransformedResponse<JoinRequest[]>, JoinRequestArgs>({
      query: (args) => {
        const queryString = prepareDataViewsQueryStrings({
          args,
          additionalFieldsToOmit: ['userGroupId'],
        });

        return {
          url: `/front/groups-management/${args.userGroupId}/requests?${queryString}`,
        };
      },
      transformResponse: formatResponse,
      providesTags: [BusinessAccountCacheTags.JOIN_REQUESTS],
    }),

    requestJoinRequestStateUpdate: builder.mutation<
      JoinRequest,
      { userGroupId: number; requestId: number; state: JoinRequestStates }
    >({
      query: (args) => {
        return {
          url: `/front/groups-management/${args.userGroupId}/requests/${args.requestId}`,
          requestParams: {
            method: 'PATCH',
            data: {
              state: args.state,
            },
          },
          config: {
            onRequestSuccess: () => {
              NotificationStore.addNotification({
                type: 'success',
                title: t({ id: 'general.notification.success', message: 'Success' }),
                content:
                  args.state === 'APPROVED'
                    ? t({
                        id: 'businessAccounts.notification.joinRequestApproved',
                        message: 'Join request successfully approved',
                      })
                    : t({
                        id: 'businessAccounts.notification.joinRequestDenied',
                        message: 'Join request successfully denied',
                      }),
              });
            },
          },
        };
      },
      invalidatesTags: [BusinessAccountCacheTags.JOIN_REQUESTS, BusinessAccountCacheTags.MEMBERS],
    }),

    requestJoinBusinessAccount: builder.mutation<void, string>({
      query: (businessAccountCode) => {
        return {
          url: `/front/groups/${businessAccountCode}/join`,
          requestParams: {
            method: 'post',
          },
          config: {
            displayNotificationOnError: false,
          },
        };
      },
      invalidatesTags: (data, error) => {
        if (error) {
          return [];
        }

        return [BusinessAccountCacheTags.JOIN_REQUESTS_MEMBERSHIP];
      },
    }),

    requestJoinBusinessAccountRequests: builder.query<JoinRequestMembership[], void>({
      query: () => {
        return {
          url: `/front/groups/requests?state=REQUESTED`,
        };
      },
      providesTags: [BusinessAccountCacheTags.JOIN_REQUESTS_MEMBERSHIP],
    }),

    requestRetractJoinBusinessAccount: builder.mutation<void, number>({
      query: (requestID) => {
        return {
          url: `/front/groups/requests/${requestID}`,
          requestParams: {
            method: 'PATCH',
            data: {
              state: 'RETRACTED',
            },
          },
        };
      },
      invalidatesTags: [BusinessAccountCacheTags.JOIN_REQUESTS_MEMBERSHIP],
    }),

    requestBusinessAccountLeave: builder.mutation<void, string>({
      query: (businessAccountCode) => {
        return {
          url: `/front/groups/${businessAccountCode}/leave`,
          requestParams: {
            method: 'POST',
          },
          config: {
            onRequestSuccess: () => {
              NotificationStore.addNotification({
                type: 'success',
                title: t({ id: 'general.notification.success', message: 'Success' }),
                content: t({
                  id: 'businessAccounts.notification.leaveSuccess',
                  message: 'You have successfully left business account.',
                }),
              });
            },
          },
        };
      },
      invalidatesTags: [BusinessAccountCacheTags.BUSINESS_ACCOUNT_MEMBERSHIP],
    }),

    requestBusinessAccountTripsById: builder.query<
      BusinessAccountTripDetails,
      BusinessAccountTripsSingleArgs
    >({
      query: ({ id, userGroupId }) => {
        return {
          url: `/front/bookings/${id}`,
          requestParams: {
            params: {
              userGroupId,
            },
          },
        };
      },
    }),

    requestMembers: builder.query<TransformedResponse<Member[]>, MembersArgs>({
      query: (args) => {
        const queryString = prepareDataViewsQueryStrings({
          args,
          additionalFieldsToOmit: ['userGroupId'],
        });

        return {
          url: `/front/groups-management/${args.userGroupId}/members?${queryString}`,
        };
      },
      transformResponse: formatResponse,
      providesTags: [BusinessAccountCacheTags.MEMBERS],
    }),

    requestMember: builder.query<Member, MemberSingleArgs>({
      query: ({ id, groupId }) => ({
        url: `/front/groups-management/${groupId}/members/${id}`,
      }),
      providesTags: (data) => [{ type: BusinessAccountCacheTags.MEMBER, id: data?.guserId }],
    }),

    requestUpdateMember: builder.mutation<Member, MemberCreate>({
      query: ({ id, groupId, ...membersData }) => ({
        url: `/front/groups-management/${groupId}/members/${id}`,
        requestParams: {
          method: 'put',
          data: nullEmptyStrings(membersData, ['superiorEmail']),
        },
        config: {
          onRequestSuccess: () => {
            NotificationStore.addNotification({
              type: 'success',
              title: t({ id: 'general.notification.success', message: 'Success' }),
              content: t({
                id: 'businessAccounts.notification.memberUpdateSuccess',
                message: 'Successfully updated member.',
              }),
            });
          },
        },
      }),
      invalidatesTags: (data) => [
        { type: BusinessAccountCacheTags.MEMBER, id: data?.guserId },
        BusinessAccountCacheTags.MEMBERS,
      ],
    }),

    requestBusinessAccountBillingSummary: builder.query<BillingSummary, number>({
      query: (userGroupId) => ({
        url: `/front/groups-management/${userGroupId}/billing-summary`,
      }),
    }),
  }),
});

export const {
  useLazyRequestMembersQuery,
  useRequestBusinessAccountTripsQuery,
  useRequestBusinessAccountTripsByIdQuery,
  useRequestBusinessAccountOnDemandTripsQuery,
  useRequestBusinessAccountOnDemandTripsByIdQuery,
  useRequestBusinessAccountQuery,
  useRequestCreateBusinessAccountMutation,
  useRequestUpdateBusinessAccountMutation,
  useRequestCostCentersQuery,
  useLazyRequestCostCentersQuery,
  useRequestCostCenterCreationMutation,
  useRequestCostCenterUpdateMutation,
  useRequestSingleCostCenterQuery,
  useRequestMembersQuery,
  useRequestMemberQuery,
  useRequestUpdateMemberMutation,
  useRequestBusinessAccountMembershipQuery,
  useRequestJoinRequestsQuery,
  useRequestJoinRequestStateUpdateMutation,
  useRequestJoinBusinessAccountMutation,
  useRequestJoinBusinessAccountRequestsQuery,
  useRequestRetractJoinBusinessAccountMutation,
  useRequestBusinessAccountLeaveMutation,
  useRequestBusinessAccountBillingSummaryQuery,
} = BusinessAccountService;
