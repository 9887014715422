import { Button, Collapse, Column, Row } from '@gourban/ui-components';
import { ApiError } from '@/core/types';
import { copyToClipboard } from '@/core/utils/copyToClipboard';
import CodeSnippet from '@/core/components/CodeSnippet';
import styles from '@/core/assets/scss/components/ErrorHandlers/TraceIdCollapsable.module.scss';
import { FC } from 'react';
import { Trans } from '@lingui/react/macro';

interface TraceIdCollapsableT {
  errorResponse?: ApiError;
  errorMessage?: string;
}

const TraceIdCollapsable: FC<TraceIdCollapsableT> = ({ errorResponse, errorMessage }) => {
  const hasTraceId = errorResponse?.traceId;

  if (!hasTraceId && !errorMessage) {
    return null;
  }

  return (
    <Row>
      {errorMessage && (
        <Column marginBottom={hasTraceId ? 8 : 0} sm={12}>
          {errorMessage}
        </Column>
      )}
      {hasTraceId && (
        <Column sm={12} marginBottom={8}>
          <Collapse
            headerClassname={styles['trace-id__collapse-header']}
            contentClassname={styles['trace-id__collapse']}
            header={<Trans id="traceIdCollapsable.header">Error details</Trans>}
          >
            <CodeSnippet data={errorResponse} />
            <Button
              onClick={() => copyToClipboard(JSON.stringify(errorResponse))}
              size="small"
              variation="secondary"
            >
              <Trans id="traceIdCollapsable.copyButton">Copy error details</Trans>
            </Button>
          </Collapse>
        </Column>
      )}
    </Row>
  );
};

export default TraceIdCollapsable;
