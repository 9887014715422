import { VehicleCategoryTimeSlot } from '@/features/vehicles/types';
import { format, Locale, NearestMinutes, roundToNearestMinutes } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { DateFormats } from '@/core/enums';

export const formatTimeslotLabel = (
  timeslot: VehicleCategoryTimeSlot,
  timeZone: string,
  locale: Locale,
  timeGap?: NearestMinutes,
) => {
  return `${format(
    roundToNearestMinutes(toZonedTime(new Date(timeslot.startTime), timeZone), {
      nearestTo: timeGap,
      roundingMethod: 'ceil',
    }),
    DateFormats.FULL_DATE_TIME_NO_YEAR,
    {
      locale,
    },
  )} -
    ${format(
      roundToNearestMinutes(toZonedTime(new Date(timeslot.endTime), timeZone), {
        nearestTo: timeGap,
        roundingMethod: 'floor',
      }),
      DateFormats.FULL_DATE_TIME_NO_YEAR,
      {
        locale,
      },
    )}`;
};
