import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import styles from '../../assets/scss/components/Layouts/DashboardLayout.module.scss';
import Sidebar from '../Dashboard/Sidebar';
import { useResponsive } from '@/core/hooks/useResponsive';
import cn from 'classnames';
import WhiteLabelLogo from '@/core/components/UI/WhiteLabelLogo';
import { Button } from '@gourban/ui-components';

const DashboardLayout = () => {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const { isMobile } = useResponsive();
  const { pathname } = useLocation();

  useEffect(() => {
    if (isMobile) {
      setOpenSidebar(false);
    }
  }, [pathname, isMobile]);

  return (
    <div className={cn(styles.dashboard, isMobile && styles['dashboard--responsive'])}>
      <div
        className={cn(
          styles.dashboard__sidebar,
          openSidebar && styles['dashboard__sidebar--active'],
        )}
      >
        <Sidebar onResponsiveClose={() => setOpenSidebar(false)} />
      </div>

      {isMobile && (
        <div className={styles['dashboard__responsive-header']}>
          <div className={styles['dashboard__responsive-header-logo']}>
            <WhiteLabelLogo type="small" />
          </div>
          <div className={styles['dashboard__responsive-header-menu']}>
            <Button
              onClick={() => setOpenSidebar(true)}
              variation="link"
              iconColoringMode="stroke"
              iconName="hamburger"
            />
          </div>
        </div>
      )}

      <main className={styles.dashboard__main}>
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardLayout;
