import React, { FC, useEffect, useState, useRef } from 'react';
import { BookingExtended } from '@/features/booking/types';
import {
  Button,
  Column,
  ConfirmationModal,
  Heading,
  Loader,
  NoResults,
  NotificationStore,
  Paragraph,
  Row,
  Separator,
} from '@gourban/ui-components';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import { useNavigate, useParams } from 'react-router';
import {
  useRequestDrivingLogQuery,
  useRequestDrivingLogSubmitMutation,
  useRequestRemoveDrivingLogEntryMutation,
} from '@/features/myTrips/services/DrivingLog.service';
import { differenceInDays } from 'date-fns';
import { ApiError } from '@/core/types';
import ErrorFallback from '@/core/components/ErrorHandlers/ErrorFallback';
import TripDrivingLogEntriesTable from '@/features/myTrips/components/TripDrivingLog/TripDrivingLogEntriesTable';
import { BookingService } from '@/features/booking/services/Booking.service';
import { BookingCacheTags } from '@/features/booking/enums';
import { useDispatch } from 'react-redux';

interface TripDrivingLogT {
  trip: BookingExtended;
  isReadOnly?: boolean;
}

const TripDrivingLog: FC<TripDrivingLogT> = ({ trip, isReadOnly = false }) => {
  const { userGroupId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: drivingLog,
    isFetching: isFetchingDrivingLog,
    isError,
    error,
  } = useRequestDrivingLogQuery(trip.rentalId!);
  const [drivingLogEntryConfirmationRemoval, setDrivingLogEntryConfirmationRemoval] = useState<
    number | false
  >(false);
  const [drivingLogSubmitConfirmation, setDrivingLogSubmitConfirmation] = useState<boolean>(false);
  const [removeDrivingLog] = useRequestRemoveDrivingLogEntryMutation();
  const [submitDrivingLog, { isLoading: isSubmittingDrivingLog }] =
    useRequestDrivingLogSubmitMutation();
  const isDrivingLogSubmitted = drivingLog?.status === 'SUBMITTED';
  const alreadyWarnedUser = useRef<boolean>(false);

  useEffect(() => {
    if (
      !isError &&
      !isFetchingDrivingLog &&
      drivingLog?.status === 'INVALID' &&
      !alreadyWarnedUser.current &&
      !isReadOnly
    ) {
      alreadyWarnedUser.current = true;
      NotificationStore.addNotification({
        type: 'warning',
        duration: 7000,
        title: (
          <Trans id="myTrips.notification.invalidDrivingLogTitle">
            Your driving log is not valid
          </Trans>
        ),
        content: (
          <Trans id="myTrips.notification.invalidDrivingLogDescription">
            Please fill out your driving log.
          </Trans>
        ),
      });
    }
  }, [isError, isFetchingDrivingLog, drivingLog, isReadOnly]);

  if (isError) {
    if ((error as ApiError)?.errorCode === 'DL100.1') {
      return (
        <NoResults
          title={t({ id: 'myTrips.tripDrivingLog.noResults', message: 'No driving logs found' })}
          description={
            <Trans id="myTrips.tripDrivingLog.noResults.description">
              No driving logs found for this rental.
            </Trans>
          }
        />
      );
    }

    return <ErrorFallback />;
  }

  if (isFetchingDrivingLog) {
    return (
      <Row justify="center">
        <Loader />
      </Row>
    );
  }

  return (
    <>
      <Row alignItems="center" justify="space-between">
        {isSubmittingDrivingLog && <Loader cover />}
        <Column>
          <Row column>
            <Column marginBottom={0}>
              <Heading marginBottom={8} size={5}>
                <Trans id="myTrips.drivingLog.title">Driving log</Trans> -{' '}
                {trip.rental?.vehicle?.name || trip.rental?.vehicle?.licensePlate}
              </Heading>
            </Column>
            <Column>
              <Paragraph size={5}>
                {!isDrivingLogSubmitted && (
                  <Trans id="myTrips.drivingLog.deadline">
                    {differenceInDays(new Date(trip.drivingLog?.dueDate!), new Date())} days left
                    until deadline
                  </Trans>
                )}
                {isDrivingLogSubmitted && (
                  <Trans id="myTrips.drivingLog.submitted">Submitted</Trans>
                )}
              </Paragraph>
            </Column>
          </Row>
        </Column>
        {!isDrivingLogSubmitted && !isReadOnly && (
          <Column>
            <Row gapSm="xs">
              <Column>
                <Button
                  loading={isSubmittingDrivingLog}
                  onClick={() => setDrivingLogSubmitConfirmation(true)}
                  disabled={!drivingLog?.validationResult?.isValid}
                  size="small"
                >
                  <Trans id="general.submit">Submit</Trans>
                </Button>
              </Column>
              <Column>
                <Button onClick={() => navigate('create')} size="small">
                  <Trans id="general.add">Add</Trans>
                </Button>
              </Column>
            </Row>
          </Column>
        )}
      </Row>

      <Separator gapBottom={32} />

      {drivingLog && (
        <TripDrivingLogEntriesTable
          isReadOnly={isReadOnly}
          isDrivingLogSubmitted={isDrivingLogSubmitted}
          drivingLog={drivingLog}
          onRequestEdit={(id) => navigate(`${id}/edit`)}
          onRequestDelete={(id) => setDrivingLogEntryConfirmationRemoval(id)}
        />
      )}

      {drivingLog?.entries && !drivingLog.entries.length && !userGroupId && (
        <NoResults
          title={t({ id: 'myTrips.tripDrivingLog.noResults', message: 'No driving logs found' })}
          description={
            <Row justify="center">
              <Column>
                <Button onClick={() => navigate('create')} size="small">
                  <Trans id="myTrips.tripDrivingLog.addDrivingLog">Add driving log</Trans>
                </Button>
              </Column>
            </Row>
          }
        />
      )}

      <ConfirmationModal
        type="primary"
        onConfirm={() =>
          submitDrivingLog(trip.rentalId!)
            .unwrap()
            .then(() => {
              dispatch(BookingService.util?.invalidateTags([BookingCacheTags.MY_TRIPS]));
            })
        }
        opened={drivingLogSubmitConfirmation}
        onCancel={() => setDrivingLogSubmitConfirmation(false)}
        title={<Trans id="myTrips.tripDrivingLog.submitConfirmation">Submit driving log</Trans>}
        deleteMessage={
          <Trans id="myTrips.tripDrivingLog.submitConfirmationDescription">
            Are you sure you want to submit this driving log? After submitting, you will not longer
            be able to edit this driving log.
          </Trans>
        }
      />

      <ConfirmationModal
        onConfirm={() =>
          removeDrivingLog({
            drivingLogEntryId: drivingLogEntryConfirmationRemoval as number,
            rentalId: trip.rentalId!,
          })
        }
        opened={!!drivingLogEntryConfirmationRemoval}
        onCancel={() => setDrivingLogEntryConfirmationRemoval(false)}
        title={<Trans id="myTrips.tripDrivingLog.removeConfirmation">Remove driving log</Trans>}
        deleteMessage={
          <Trans id="myTrips.tripDrivingLog.removeConfirmationDescription">
            Are you sure you want to remove this driving log entry?
          </Trans>
        }
      />
    </>
  );
};

export default TripDrivingLog;
