import { FC, memo } from 'react';
import skeletonLoaderModules from '@/core/assets/scss/components/SkeletonLoader.module.scss';
import cn from 'classnames';
import { Skeleton } from '@/core/types';
import { Column, Row } from '@gourban/ui-components';

const SkeletonLoader: FC<Skeleton> = ({
  withSidebar,
  withContentHeader,
  numberOfContentRows = 1,
}) => {
  return (
    <Row
      noWrap
      gapSm="sm"
      justify="space-between"
      className={cn(
        skeletonLoaderModules.skeleton__loader,
        withSidebar && skeletonLoaderModules['skeleton__loader--with-sidebar'],
      )}
    >
      {withSidebar && (
        <Column className={skeletonLoaderModules['skeleton__loader-sidebar']}>
          <Row column>
            <Column
              marginBottom={16}
              className={skeletonLoaderModules['skeleton__loader-sidebar-item']}
            />
            <Column
              marginBottom={16}
              className={skeletonLoaderModules['skeleton__loader-sidebar-item']}
            />
            <Column
              marginBottom={16}
              className={skeletonLoaderModules['skeleton__loader-sidebar-item']}
            />
            <Column
              marginBottom={16}
              className={skeletonLoaderModules['skeleton__loader-sidebar-item']}
            />
            <Column
              marginBottom={16}
              className={skeletonLoaderModules['skeleton__loader-sidebar-item']}
            />
            <Column
              marginBottom={16}
              className={skeletonLoaderModules['skeleton__loader-sidebar-item']}
            />
          </Row>
        </Column>
      )}

      <Column grow>
        <Row noWrap column className={skeletonLoaderModules['skeleton__loader-content']}>
          {withContentHeader && (
            <Column marginBottom={24}>
              <Row justify="space-between">
                <Column className={skeletonLoaderModules['skeleton__loader-content-title']} />
                <Column className={skeletonLoaderModules['skeleton__loader-content-button']} />
              </Row>
            </Column>
          )}
          {Array.from({ length: numberOfContentRows })
            .map((_, index) => index)
            .map((row) => {
              return (
                <Column
                  key={row}
                  marginBottom={16}
                  grow
                  className={skeletonLoaderModules['skeleton__loader-content__table']}
                />
              );
            })}
        </Row>
      </Column>
    </Row>
  );
};

export default memo(SkeletonLoader);
