import { BookingStep, BookingStepKeys, BookingSteps, StepRules } from '@/features/booking/types';
import { msg } from '@lingui/core/macro';
import { useCallback, useEffect, useRef, useState } from 'react';
import { entries, isEqual } from 'lodash';
import { useParams } from 'react-router';

const bookingFlowAvailableSteps: BookingSteps = {
  date: {
    label: msg({ id: 'bookings.createBreadcrumbs.date', message: 'Date' }),
    status: 'active',
    rules: {},
  },
  account: {
    label: msg({ id: 'bookings.createBreadcrumbs.account', message: 'Account' }),
    status: 'inactive',
  },
  vehicleCategory: {
    label: msg({ id: 'bookings.createBreadcrumbs.vehicleCategory', message: 'Vehicle category' }),
    status: 'inactive',
  },
  vehicleCategorySummary: { status: 'inactive' },
  extras: {
    label: msg({ id: 'bookings.createBreadcrumbs.extras', message: 'Extras' }),
    status: 'inactive',
  },
  summary: { status: 'inactive' },
  thankYou: { status: 'inactive' },
};

/**
 * This hook is used to generate steps for different booking flows
 */
export const useBookingFlowSteps = <Steps extends Partial<BookingSteps>>(
  steps: BookingStepKeys[],
  rules?: StepRules,
) => {
  const { branchId } = useParams();

  const mergeBookingFlowSteps = useCallback(
    (bookingSteps: BookingStepKeys[], stepsData: Steps, stepRules?: StepRules) => {
      return bookingSteps.reduce((acc, step) => {
        acc[step] = { ...(stepsData[step] as BookingStep), rules: stepRules?.[step] ?? {} };

        return acc;
      }, {} as Steps);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [bookingFlowSteps, setBookingFlowSteps] = useState<Steps>(
    mergeBookingFlowSteps(steps, bookingFlowAvailableSteps as Steps, rules),
  );

  const rulesRef = useRef<StepRules | undefined>(rules);

  useEffect(() => {
    const bookingStepsAreDifferent = entries(bookingFlowAvailableSteps).some(
      ([key, availableStep]) => {
        const stepKey = key as BookingStepKeys;
        return availableStep.status !== bookingFlowSteps[stepKey]?.status;
      },
    );

    if (bookingStepsAreDifferent) {
      setBookingFlowSteps(mergeBookingFlowSteps(steps, bookingFlowAvailableSteps as Steps, rules));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  useEffect(() => {
    if (!isEqual(rulesRef?.current, rules)) {
      setBookingFlowSteps((currentSteps) => mergeBookingFlowSteps(steps, currentSteps!, rules));
      rulesRef.current = rules;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules]);

  return { steps: bookingFlowSteps, setSteps: setBookingFlowSteps };
};
