import React, { useMemo } from 'react';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import { Card, Column, Flex, Paragraph, Row, Separator } from '@gourban/ui-components';
import styles from '../../assets/scss/components/Filters/FlexibleDate.module.scss';
import {
  addMonths,
  eachMonthOfInterval,
  endOfMonth,
  format,
  isSameMonth,
  startOfMonth,
} from 'date-fns';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { useFormikContext } from 'formik';
import { BookingFiltersForm } from '@/features/booking/types';
import cn from 'classnames';

const FlexibleDate = () => {
  const locale = useDateLocale();
  const { values, setFieldValue } = useFormikContext<BookingFiltersForm>();

  const duration = useMemo(() => {
    return [
      {
        name: t({ id: 'filters.flexibleDate.weekend', message: 'Weekend' }),
        value: 'WEEKEND',
      },
      {
        name: t({ id: 'filters.flexibleDate.week', message: 'Work days' }),
        value: 'WEEK',
      },
    ];
  }, []);

  const months = useMemo(() => {
    return eachMonthOfInterval({ start: new Date(), end: addMonths(new Date(), 7) });
  }, []);

  return (
    <>
      <Paragraph marginBottom={8} textColor="text-950">
        <Trans id="filters.flexibleDate.choosePeriod">Choose a period</Trans>
      </Paragraph>

      <Flex gap="sm">
        {duration.map((item) => (
          <Card
            className={cn(
              styles['flexible-date__duration-card'],
              values.flexibleDurationType === item.value &&
                styles['flexible-date__duration-card--selected'],
            )}
            onClick={() => {
              if (values.flexibleDurationType && values.flexibleDurationType === item.value) {
                void setFieldValue('flexibleDurationType', undefined);
              } else {
                void setFieldValue('flexibleDurationType', item.value);
              }
            }}
            radius="sm"
            key={item.name}
          >
            <Paragraph marginBottom={0} align="center">
              {item.name}
            </Paragraph>
          </Card>
        ))}
      </Flex>

      <Separator gapBottom={16} backgroundColor="transparent" />

      <Paragraph marginBottom={8} textColor="text-950">
        <Trans id="filters.flexibleDate.chooseMonth">Choose month</Trans>
      </Paragraph>

      <Row gapSm="xs">
        {months.map((month) => {
          return (
            <Column key={month.toISOString()} sm={3}>
              <Card
                onClick={() => {
                  if (
                    values.bookingDate?.from &&
                    isSameMonth(new Date(values.bookingDate.from), month)
                  ) {
                    void setFieldValue('bookingDate', undefined);

                    void setFieldValue('bookingTime', undefined);
                  } else {
                    void setFieldValue('bookingDate', {
                      from: startOfMonth(month),
                      to: endOfMonth(month),
                    });

                    void setFieldValue('bookingTime', {
                      from: '00:00',
                      to: '23:59',
                    });
                  }
                }}
                className={cn(
                  styles['flexible-date__month-card'],
                  values.bookingDate?.from &&
                    isSameMonth(month, new Date(values.bookingDate?.from)) &&
                    styles['flexible-date__month-card--selected'],
                )}
              >
                <Paragraph marginBottom={0} align="center">
                  {format(month, 'MMMM', { locale })} <br />
                  {format(month, 'yyyy', { locale })}
                </Paragraph>
              </Card>
            </Column>
          );
        })}
      </Row>
    </>
  );
};

export default FlexibleDate;
