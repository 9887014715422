import React, { FC, useMemo } from 'react';
import { Addition } from '@/features/vehicles/types';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import { Svg, Tooltip } from '@gourban/ui-components';
import styles from '@/features/booking/assets/scss/components/BookingFlow/AdditionPriceBreakdown.module.scss';
import { Trans } from '@lingui/react/macro';
import CurrencyDisplay from '@/core/components/CurrencyDisplay';
import UnitDisplay from '@/core/components/UnitDisplay';

interface AdditionPriceBreakdownT {
  addition: Addition;
  currency?: string;
}

type AdditionPricingType = 'unlock' | 'driving' | 'reservation' | 'parking' | 'estimation';

const AdditionPriceBreakdown: FC<AdditionPriceBreakdownT> = ({ addition, currency }) => {
  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const { showGrossPrice } = bookingSettings?.value ?? {};
  const estimatedPrice = addition.pricingInformation?.estimation;

  const translateAdditionPricingTypes = (pricingType: AdditionPricingType) => {
    switch (pricingType) {
      case 'driving':
        return <Trans id="bookings.additionPrice.driving">Drive</Trans>;

      case 'reservation':
        return <Trans id="bookings.additionPrice.reservation">Reservation</Trans>;

      case 'unlock':
        return <Trans id="bookings.additionPrice.unlock">Start</Trans>;

      case 'parking':
        return <Trans id="bookings.additionPrice.parking">Parking</Trans>;

      case 'estimation':
        return <Trans id="bookings.additionPrice.estimation">Estimated total</Trans>;

      default:
        return '';
    }
  };

  const pricingConfigurations = useMemo(() => {
    return Object.entries(addition.pricingInformation).filter(
      ([pricingKey, { gross }]) => gross > 0 && pricingKey !== 'estimation',
    );
  }, [addition]);

  if (!pricingConfigurations?.length) {
    return null;
  }

  return (
    <Tooltip
      placement="left"
      content={
        <span className={styles['addition-price-breakdown__content']}>
          {pricingConfigurations.map(([pricingType, { timeUnit, net, gross }]) => {
            const price = showGrossPrice ? gross : net;

            if (timeUnit) {
              return (
                <span key={addition.id + pricingType}>
                  {translateAdditionPricingTypes(pricingType as AdditionPricingType)}:{' '}
                  <CurrencyDisplay value={price} currency={currency!} />
                  <UnitDisplay unit={timeUnit} unitDisplay="shortPer" />
                </span>
              );
            }

            return (
              <span key={addition.id + pricingType}>
                {translateAdditionPricingTypes(pricingType as AdditionPricingType)}:{' '}
                <CurrencyDisplay value={price} currency={currency!} />
              </span>
            );
          })}

          {estimatedPrice && (
            <span>
              {translateAdditionPricingTypes('estimation' as AdditionPricingType)}:{' '}
              <CurrencyDisplay
                value={showGrossPrice ? estimatedPrice.gross : estimatedPrice.net}
                currency={currency!}
              />
            </span>
          )}
        </span>
      }
    >
      <Svg
        className={styles['addition-price-breakdown']}
        icon="infoFilled"
        color="icon-300"
        width={16}
      />
    </Tooltip>
  );
};

export default AdditionPriceBreakdown;
