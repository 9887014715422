import { FC } from 'react';
import { Outlet } from 'react-router';
import { useRequestMeDataQuery } from '@/features/account/services/Account.service';
import { Loader } from '@gourban/ui-components';
import { useRequestUserRequiredFieldsQuery } from '@/core/services/SettingsManagement.service';
import ErrorFallback from '@/core/components/ErrorHandlers/ErrorFallback';
import { Trans } from '@lingui/react/macro';

const AccountEdit: FC = () => {
  const { isLoading: isLoadingMeData, isError: errorFetchingMeData } = useRequestMeDataQuery();
  const { isLoading: isLoadingUserRequiredFields, isError: errorFetchingUserRequiredFields } =
    useRequestUserRequiredFieldsQuery();

  if (errorFetchingMeData || errorFetchingUserRequiredFields) {
    return (
      <ErrorFallback
        message={
          <Trans id="errorFallback.dataFailedToLoad">Data failed to load. Try again later.</Trans>
        }
      />
    );
  }

  if (isLoadingMeData || isLoadingUserRequiredFields) {
    return <Loader cover />;
  }

  return <Outlet />;
};

export default AccountEdit;
