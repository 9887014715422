import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AccountStateT, StepName } from '../types';
import { AvailableBookingStationFilters, AvailableBookingStations } from '@/features/booking/types';
import { LngLat } from '@/features/geomap/types';
import { BranchesService } from '@/features/branches/services/Branches.service';
import { PaymentService } from '@/features/payment/services/Payment.service';

const initialState: AccountStateT = {
  steps: {
    'basic-info': {
      isComplete: false,
    },
    payment: {
      isComplete: false,
      selectedCardId: null,
      provider: null,
      promoCode: null,
    },
    verification: {
      isComplete: false,
    },
  },
  clearFiltersRequested: false,
  activeFilterValues: null,
  filteredStationsData: null,
  isFetchingFilteredStationsData: false,
  bookingCreationProcessStarted: false,
  userLocationCoordinates: null,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetActiveFilters: (state) => {
      state.activeFilterValues = null;
    },
    setClearFiltersRequested: (state, { payload }: PayloadAction<boolean>) => {
      state.clearFiltersRequested = payload;
    },
    setActiveFilterValues: (
      state,
      { payload }: PayloadAction<Partial<AvailableBookingStationFilters>>,
    ) => {
      state.activeFilterValues = payload;
    },
    setIsFetchingFilteredStationsData: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingFilteredStationsData = payload;
    },
    setFilteredStationsData: (state, { payload }: PayloadAction<AvailableBookingStations[]>) => {
      state.filteredStationsData = payload;
    },
    setBookingCreationProcessStarted: (state, { payload }: PayloadAction<boolean>) => {
      state.bookingCreationProcessStarted = payload;
    },
    resetAccountState: (state) => {
      state.steps = initialState.steps;
    },
    setUserLocationCoordinates: (state, { payload }: PayloadAction<LngLat>) => {
      state.userLocationCoordinates = payload;
    },
    setStepComplete: (
      state,
      { payload: { isComplete, name } }: PayloadAction<{ name: StepName; isComplete: boolean }>,
    ) => {
      state.steps[name].isComplete = isComplete;
    },
    setSelectedCardId: (state, { payload }: PayloadAction<string>) => {
      state.steps.payment.selectedCardId = payload;
    },
    setPromoCode: (state, { payload }: PayloadAction<string | null>) => {
      state.steps.payment.promoCode = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      BranchesService.endpoints?.requestResolvedBranch.matchFulfilled,
      (state, { payload }) => {
        state.steps.payment.provider = payload.paymentProperties.provider;
      },
    );
    builder.addMatcher(
      PaymentService.endpoints?.requestPaymentSources.matchFulfilled,
      (state, { payload }) => {
        state.steps.payment.selectedCardId = payload?.[0]?.id;
      },
    );
  },
});

const { actions, reducer } = accountSlice;

export const {
  resetAccountState,
  setStepComplete,
  setSelectedCardId,
  setPromoCode,
  setActiveFilterValues,
  resetActiveFilters,
  setClearFiltersRequested,
  setUserLocationCoordinates,
  setBookingCreationProcessStarted,
  setFilteredStationsData,
  setIsFetchingFilteredStationsData,
} = actions;

export { reducer as accountReducer };
