import { Route } from '@/core/types';
import { auth, resetPassword } from '@/core/pages/Auth/routes';
import RedirectIfAuth from '@/features/auth/components/RedirectIfAuth';
import ResetPassword from '@/core/pages/Auth/ResetPassword';
import { Outlet } from 'react-router';
import { businessAccountApplication } from '@/core/pages/BusinessAccounts/routerConfig';

const authRoutes: Route = {
  ...auth,
  element: <RedirectIfAuth />,
  routes: [
    {
      ...resetPassword,
      element: <ResetPassword />,
    },
  ],
};

export const publicRoutes = {
  path: '/public',
  label: 'Public routes',
  guarded: false,
  element: <Outlet />,
  routes: [businessAccountApplication],
};

const routes: Route[] = [authRoutes, publicRoutes];

export default routes;
