import React, { FC, ReactNode } from 'react';
import { Column, Svg, Row, Heading, Button, Paragraph } from '@gourban/ui-components';
import styles from '@/features/account/assets/Verification/VerifficationMessage.module.scss';
import { VerificationStatus } from '@/features/account/hooks/useVerificationStatus';
import { IconName } from '@gourban/ui-components/dist/lib/types';
import cn from 'classnames';
import { Trans } from '@lingui/react/macro';

export type VerificationMessageSize = 'small' | 'large';

interface VerificationMessageT {
  verificationStatus: VerificationStatus;
  onTryAgain: () => void;
  size?: VerificationMessageSize;
}

export const VerificationMessage: FC<VerificationMessageT> = ({
  verificationStatus,
  onTryAgain,
  size = 'large',
}) => {
  let icon: IconName;
  let message: ReactNode;
  let header: ReactNode;
  const rejected = verificationStatus === 'rejected';
  const retry = verificationStatus === 'retry';
  const error = rejected || retry;

  const getIconSize = () => {
    if (error) {
      if (size === 'small') {
        return 18;
      }

      return 35;
    }

    if (size === 'small') {
      return 18;
    }

    return 25;
  };

  const iconSize = getIconSize();

  if (verificationStatus === 'uninitialized') return null;

  switch (verificationStatus) {
    case 'pending':
      icon = 'reset';
      header = <Trans id="account.verification.inProgressHeader">In progress</Trans>;
      message = (
        <Trans id="account.verification.inProgressMessage">
          Your verification process is currently in progress.
        </Trans>
      );
      break;
    case 'successful':
      icon = 'checkmark';
      header = <Trans id="account.verification.successfulHeader">Success</Trans>;
      message = (
        <Trans id="account.verification.successfulMessage">
          Your verification data has been successfully verified.
        </Trans>
      );
      break;
    case 'rejected':
      icon = 'close';
      header = <Trans id="account.verification.rejectedHeader">Rejected</Trans>;
      message = (
        <Trans id="account.verification.rejectedMessage">
          Your verification data has been rejected.
        </Trans>
      );
      break;
    case 'retry':
    default: // not needed, but lint rule requires this
      icon = 'close';
      header = <Trans id="account.verification.retryHeader">Rejected</Trans>;
      message = (
        <Trans id="account.verification.retryMessage">
          We failed to verify you. Please try again to verify your driving licence card.
        </Trans>
      );
      break;
  }

  return (
    <>
      <Row column alignItems="center" className={styles['verification-message']}>
        <Column
          marginBottom={16}
          className={cn(
            styles['verification-message__icon'],
            styles[`verification-message__icon--${size}`],
            verificationStatus === 'pending' && styles['verification-message__icon--rotate'],
            error && styles['verification-message__icon--error'],
          )}
        >
          <Svg icon={icon} width={iconSize} height={iconSize} />
        </Column>
        <Column marginBottom={size === 'small' ? 8 : 16}>
          <Heading align="center" size={size === 'small' ? 4 : 3}>
            {header}
          </Heading>
        </Column>
        <Column>
          <Paragraph align="center" size={size === 'small' ? 4 : 3}>
            {message}
          </Paragraph>
        </Column>
      </Row>
      <Row justify="center">
        {retry && (
          <Button onClick={() => onTryAgain()}>
            <Trans id="general.tryAgain">Try Again</Trans>
          </Button>
        )}
      </Row>
    </>
  );
};
