import React, { FC, useEffect } from 'react';
import { Loader, NotificationStore } from '@gourban/ui-components';
import { ProvidedModal } from '@/core/components/UI/modals/ModalProvider';
import {
  useRequestBookingUpdateMutation,
  useRequestCanExtendBookingQuery,
} from '@/features/booking/services/Booking.service';
import { BookingCreationArgs, BookingSteps, BookingUpdateArgs } from '@/features/booking/types';
import { useBookingPreparation } from '@/features/booking/hooks/useBookingPreparation';
import OverviewModal from '../OverviewModal/OverviewModal';
import { useBookingFlowSteps } from '@/features/booking/hooks/useBookingFlowSteps';
import BookingFlow from '@/features/booking/components/BookingFlow/BookingFlow';
import ErrorFallback from '@/core/components/ErrorHandlers/ErrorFallback';
import { Trans } from '@lingui/react/macro';
import { pick } from 'lodash';

export interface ExtendBookingT extends ProvidedModal {
  bookingId: number;
  branchId: number;
}

interface ExtendBookingSteps extends Pick<BookingSteps, 'date' | 'summary' | 'thankYou'> {}

const ExtendBooking: FC<ExtendBookingT> = ({ bookingId, branchId, onCancel }) => {
  const {
    data: extendStatus,
    isFetching: isFetchingExtendBookingStatus,
    error: extendError,
  } = useRequestCanExtendBookingQuery(bookingId);
  const [updateBooking] = useRequestBookingUpdateMutation();

  const {
    isFetchingBookingData,
    bookingInitialValues,
    isError: isBookingError,
    bookingData,
  } = useBookingPreparation(String(branchId), String(bookingId), 'ACCEPTED');

  const { steps, setSteps } = useBookingFlowSteps<ExtendBookingSteps>(
    ['date', 'summary', 'thankYou'],
    {
      date: {
        maxDate: extendStatus?.extendableUpToTime,
        minDate: bookingData?.endTime,
        preventSelectionCancel: true,
        disableStartTimeSelection: true,
        hideResetButton: true,
      },
      thankYou: {
        hideFreeCancellationInfo: true,
      },
    },
  );

  const isFetching = isFetchingBookingData || isFetchingExtendBookingStatus;
  const isError = !!(extendError || isBookingError);

  const onBookingSubmit = async (values: BookingCreationArgs) => {
    const data = {
      id: String(bookingId),
      ...pick(values, ['branchId', 'startTime', 'endTime']),
    } as unknown as BookingUpdateArgs;

    try {
      const booking = await updateBooking(data).unwrap();

      setSteps((prev) => ({
        ...prev,
        summary: { ...prev.summary, status: 'completed' },
        thankYou: { ...prev.thankYou, status: 'active' },
      }));

      return await Promise.resolve(booking);
    } catch (e) {
      onCancel?.();
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    if (extendStatus?.extendable === false) {
      NotificationStore.addNotification({
        type: 'warning',
        title: <Trans id="modals.extendBooking.cantExtendTitle">Cant extend booking</Trans>,
        content: (
          <Trans id="modals.extendBooking.cantExtendDescription">
            This booking can no longer be extended.
          </Trans>
        ),
      });
    }
  }, [extendStatus]);

  if (isFetching) {
    return <Loader zIndex={100} cover />;
  }

  if (isError) {
    return (
      <OverviewModal displayFallback={false} onClose={() => onCancel?.()}>
        <ErrorFallback />
      </OverviewModal>
    );
  }

  if (extendStatus?.extendable === false) {
    return null;
  }

  return (
    <BookingFlow<ExtendBookingSteps>
      onClose={() => onCancel?.()}
      onBookingCompleted={() => onCancel?.()}
      onBookingSubmit={onBookingSubmit}
      bookingId={String(bookingId)}
      branchId={String(branchId)}
      flowSteps={steps}
      setSteps={setSteps}
      initialValues={bookingInitialValues}
    />
  );
};

export default ExtendBooking;
