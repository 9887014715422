import React, { FC } from 'react';
import { Column, DatePickerInput, PhoneNumber, Row, Text } from '@gourban/ui-components';
import { subYears } from 'date-fns';
import CitySelect from '@/features/geomap/components/Form/CitySelect';
import CountrySelect from '@/features/geomap/components/Form/CountrySelect';
import AddressSelect from '@/features/geomap/components/Form/AddressSelect';
import { t } from '@lingui/core/macro';
import { useProfileSchema } from '@/features/auth/hooks/useProfileSchema';
import { useRequestMeDataQuery } from '@/features/account/services/Account.service';
import { useResponsive } from '@/core/hooks/useResponsive';

interface BasicInfoFormProps {
  signupType: 'PHONE' | 'EMAIL';
}

const BasicInfoForm: FC<BasicInfoFormProps> = ({ signupType }) => {
  const { data: meData } = useRequestMeDataQuery();
  const { profile } = useProfileSchema();
  const { isMobile } = useResponsive();

  return (
    <Row gapSm="sm">
      {(profile.firstName.visible || profile.lastName.visible) && (
        <Column sm={12}>
          <Row gapSm="sm">
            {profile.firstName.visible && (
              <Column sm={profile.lastName.visible ? 6 : 12}>
                <Text
                  fieldAttr={{
                    id: 'firstName',
                    placeholder: t({
                      id: 'account.basicInfo.firstNamePlaceholder',
                      message: 'First name',
                    }),
                    required: profile.firstName.required,
                    disabled: !!meData?.firstName && !profile.firstName.editable,
                  }}
                  name="firstName"
                  fieldProps={{
                    label: t({
                      id: 'account.basicInfo.firstNameLabel',
                      message: 'First name',
                    }),
                  }}
                />
              </Column>
            )}
            {profile.lastName.visible && (
              <Column sm={profile.firstName.visible ? 6 : 12}>
                <Text
                  fieldAttr={{
                    id: 'lastName',
                    placeholder: t({
                      id: 'account.basicInfo.lastNamePlaceholder',
                      message: 'Last name',
                    }),
                    required: profile.lastName.required,
                    disabled: !!meData?.lastName && !profile.lastName.editable,
                  }}
                  name="lastName"
                  fieldProps={{
                    label: t({
                      id: 'account.basicInfo.lastNameLabel',
                      message: 'Last name',
                    }),
                  }}
                />
              </Column>
            )}
          </Row>
        </Column>
      )}
      {profile.birthdate.visible && (
        <Column marginBottom={8} sm={12}>
          <DatePickerInput
            fieldAttr={{
              id: 'birthdate',
              placeholder: t({
                id: 'account.basicInfo.birthdatePlaceholder',
                message: 'Date of birth',
              }),
              required: profile.birthdate.required,
              disabled: !!meData?.birthdate && !profile.birthdate.editable,
            }}
            name="birthdate"
            fieldProps={{
              label: t({
                id: 'account.basicInfo.birthdateLabel',
                message: 'Date of birth',
              }),
              allowableSelectionRange: {
                minDate: subYears(new Date(), 100).toString(),
                maxDate: new Date().toString(),
              },
            }}
          />
        </Column>
      )}
      <Column marginBottom={8} sm={12}>
        <Text
          fieldAttr={{
            id: 'customProperties.birthPlace',
            placeholder: t({
              id: 'account.basicInfo.birthPlacePlaceholder',
              message: 'Birth place',
            }),
          }}
          name="customProperties.birthPlace"
          fieldProps={{
            label: t({
              id: 'account.basicInfo.birthPlaceLabel',
              message: 'Birth place',
            }),
          }}
        />
      </Column>
      {profile.street.visible && (
        <Column marginBottom={8} sm={12}>
          <AddressSelect
            fieldAttr={{
              placeholder: t({ id: 'geomap.addressSelect.placeholder', message: 'Address' }),
              required: profile.street.required,
              disabled: !!meData?.street && !profile.street.editable,
            }}
          />
        </Column>
      )}
      {profile.city.visible && (
        <Column marginBottom={8} sm={profile.postcode.visible && !isMobile ? 6 : 12}>
          <CitySelect
            fieldAttr={{
              placeholder: t({ id: 'geomap.citySelect.placeholder', message: 'City' }),
              required: profile.city.required,
              disabled: !!meData?.city && !profile.city.editable,
            }}
          />
        </Column>
      )}
      {profile.postcode.visible && (
        <Column marginBottom={8} sm={profile.city.visible && !isMobile ? 6 : 12}>
          <Text
            fieldAttr={{
              id: 'postcode',
              placeholder: t({
                id: 'account.basicInfo.postalCodePlaceholder',
                message: 'Postal code',
              }),
              required: profile.postcode.required,
              disabled: !!meData?.postcode && !profile.postcode.editable,
            }}
            name="postcode"
            fieldProps={{
              label: t({
                id: 'account.basicInfo.postalCodeLabel',
                message: 'Postal code',
              }),
            }}
          />
        </Column>
      )}
      {profile.country.visible && (
        <Column marginBottom={8} sm={12}>
          <CountrySelect
            fieldAttr={{
              placeholder: t({ id: 'geomap.countrySelect.placeholder', message: 'Country' }),
              required: profile.country.required,
              disabled: !!meData?.country && !profile.country.editable,
            }}
          />
        </Column>
      )}
      {signupType === 'PHONE' && profile.email.visible && (
        <Column marginBottom={8} sm={12}>
          <Text
            name="email"
            fieldAttr={{
              id: 'email',
              placeholder: t({
                id: 'auth.forms.email',
                message: 'E-mail',
              }),
              autoComplete: 'email',
              type: 'email',
              required: profile.email.required,
              disabled: !!meData?.email && !profile.email.editable,
            }}
            fieldProps={{
              label: t({
                id: 'auth.forms.email',
                message: 'E-mail',
              }),
            }}
          />
        </Column>
      )}
      {signupType === 'EMAIL' && profile.phone.visible && (
        <Column marginBottom={8} sm={12}>
          <PhoneNumber
            fieldAttr={{
              id: 'phone',
              placeholder: t({
                id: 'account.basicInfo.phonePlaceholder',
                message: 'Phone number',
              }),
              required: profile.phone.required,
              disabled: !!meData?.phone && !profile.phone.editable,
            }}
            name="phone"
            fieldProps={{
              size: 'medium',
              label: t({
                id: 'account.basicInfo.phoneLabel',
                message: 'Phone number',
              }),
            }}
          />
        </Column>
      )}
    </Row>
  );
};

export default BasicInfoForm;
