import { t } from '@lingui/core/macro';
import { format, Locale } from 'date-fns';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { DateFormats } from '@/core/enums';
import { BookingFiltersForm, DateFilterTypes } from '@/features/booking/types';

interface DateLabelStringValues
  extends Pick<BookingFiltersForm, 'bookingDate' | 'bookingTime' | 'flexibleDurationType'> {}

export const formatDateLabelString = (
  tab: DateFilterTypes,
  values: DateLabelStringValues,
  locale: Locale,
) => {
  if (!values.flexibleDurationType && !values.bookingDate?.from) {
    return undefined;
  }

  if (tab === 'FLEXIBLE') {
    const durationTypeMap = {
      WEEKEND: t({ id: 'filters.dateLabelString.weekend', message: 'Weekend in ' }),
      WEEK: t({ id: 'filters.dateLabelString.week', message: 'Work days in ' }),
      MONTH: t({ id: 'filters.dateLabelString.month', message: 'Any day in ' }),
      DEFAULT: t({ id: 'filters.dateLabelString.default', message: 'Any day in ' }),
    };

    const durationString = values.flexibleDurationType
      ? durationTypeMap[values.flexibleDurationType]
      : durationTypeMap.DEFAULT;

    const rangeString = values.bookingDate?.from
      ? format(new Date(values.bookingDate.from), 'MMMM', { locale })
      : t({ id: 'filters.dateLabelString.defaultRange', message: 'the next 15 days' });

    return durationString + rangeString;
  }

  if (values.bookingTime?.from) {
    const fromDate = mergeDateTime(values.bookingDate!.from!, values.bookingTime.from);
    const toDate = mergeDateTime(
      values.bookingDate?.to ?? values.bookingDate!.from!,
      values.bookingTime.to,
    );

    return `${format(fromDate, DateFormats.FULL_DATE_TIME_NO_YEAR, { locale })} - ${format(
      toDate,
      DateFormats.FULL_DATE_TIME_NO_YEAR,
      { locale },
    )}`;
  }

  return undefined;
};
