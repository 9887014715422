import { useState, useEffect } from 'react';

export const useDelayedUpdate = (condition: boolean, truthyDelay: number, falsyDelay: number) => {
  const [triggerUpdate, setTriggerUpdate] = useState(condition);

  useEffect(() => {
    setTimeout(
      () => {
        setTriggerUpdate(condition);
      },
      condition ? truthyDelay : falsyDelay,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  return triggerUpdate;
};
