import React, { FC, useState, useEffect } from 'react';
import { List, Loader } from '@gourban/ui-components';
import MyTrip from '@/features/myTrips/components/Trips/MyTrip';
import { useRequestMyTripsQuery } from '@/features/booking/services/Booking.service';
import { BookingArgs } from '@/features/booking/types';
import { NoTrips } from '@/features/myTrips/components/Trips/NoTrips';
import { useNavigate } from 'react-router';
import styles from '@/features/myTrips/assets/scss/components/Trips/MyTripsList.module.scss';
import { useRequestVehicleAdditionsQuery } from '@/features/vehicles/services/Vehicles.service';

export interface MyTripsListT {
  view: BookingArgs['view'];
}

const MyTripsList: FC<MyTripsListT> = ({ view }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { data: myTrips, isFetching } = useRequestMyTripsQuery({ view, page });
  const { isFetching: isFetchingAdditions } = useRequestVehicleAdditionsQuery();

  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: 'smooth' });
  }, [myTrips]);

  if (isFetching || isFetchingAdditions) return <Loader cover />;

  if (!myTrips) return null;

  if (myTrips.meta.totalElements === 0) return <NoTrips view={view} />;

  return (
    <List
      className={styles['my-trips-list']}
      meta={myTrips.meta}
      onPageChange={({ selected }) => {
        setPage(selected);
      }}
    >
      {myTrips.data.map((trip) => (
        <MyTrip
          key={trip.id}
          trip={trip}
          view={view}
          onEditClicked={() => navigate(`/booking/${trip.branch.id}/edit/${trip.id}`)}
        />
      ))}
    </List>
  );
};

export default MyTripsList;
