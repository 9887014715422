import { useRequestSingleBranchQuery } from '@/features/branches/services/Branches.service';
import { useTypedSelector } from '@/core/redux/hooks';
import { getActiveFilterValues } from '@/features/account/redux/account.selectors';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import { useMemo } from 'react';
import { TermsAndConditionsStatus } from '@/features/auth/types/User.types';
import { BookingExtended, BookingOverviewForm } from '@/features/booking/types';
import { formatInitialTime } from '@/features/booking/utils/formatInitialTime';
import { useRequestBookingByIdQuery } from '@/features/booking/services/Booking.service';
import { format, startOfMonth } from 'date-fns';
import { useRequestPaymentSourcesQuery } from '@/features/payment/services/Payment.service';
import { useDateLocale } from '@/core/hooks/useDateLocale';
import { Branch } from '@/features/branches/types';
import { toZonedTime } from 'date-fns-tz';
import { getMyTimezoneId } from '@/core/utils/getMyTimezoneId';

interface BookingPreparationReturnValue {
  isFetchingBookingData: boolean;
  bookingInitialValues: BookingOverviewForm;
  isError?: boolean;
  bookingData?: BookingExtended;
  stationData?: Branch;
}

/**
 * Initial values and initial data in general for booking are coming form different sources
 * that depends on each other, so this hooks helps to prepare all the data needed for booking
 */
export const useBookingPreparation = (
  branchId?: string,
  bookingId?: string,
  termsStatus?: TermsAndConditionsStatus,
): BookingPreparationReturnValue => {
  const locale = useDateLocale();
  const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const {
    data: branchData,
    isFetching: isFetchingBranchData,
    isError: branchFetchingError,
  } = useRequestSingleBranchQuery(branchId!, {
    skip: termsStatus !== 'ACCEPTED',
  });
  const {
    data: bookingData,
    isLoading: isLoadingBookingData,
    isError: errorFetchingBooking,
  } = useRequestBookingByIdQuery({ id: Number(bookingId) }, { skip: !bookingId });

  const { data: personalPayments } = useRequestPaymentSourcesQuery(
    {
      branchId: +branchId!,
    },
    { skip: !branchId },
  );

  const activeFilters = useTypedSelector(getActiveFilterValues);

  const {
    data: bookingSettings,
    isFetching: isFetchingBookingSettings,
    isError: errorFetchingBookingSettings,
  } = useRequestBookingSettingQuery(undefined, {
    skip: !branchData,
  });

  const initialValues = useMemo<BookingOverviewForm>(() => {
    // If booking data is present, prefill data with that booking
    if (bookingData && bookingId) {
      const start = toZonedTime(bookingData.startTime, branchData?.timeZoneId ?? getMyTimezoneId());
      const end = toZonedTime(bookingData.endTime, branchData?.timeZoneId ?? getMyTimezoneId());

      return {
        bookingDate: {
          from: start,
          to: end,
        },
        bookingTime: {
          from: format(start, 'HH:mm'),
          to: format(end, 'HH:mm'),
        },
        selectedMonth: startOfMonth(start),
        selectedPaymentType: bookingData ? bookingData.rentalType : undefined,
        selectedPaymentMethod:
          bookingData?.rentalType === 'BUSINESS'
            ? bookingData.userGroupCode
            : personalPayments?.[0]?.id,
        addition: bookingData.additions,
        reason: bookingData?.reason,
        selectedAdditions: bookingData.additions,
        selectedVehicleCategory: bookingData.vehicleCategoryId,
      };
    }

    const bookingDateInitial = (() => {
      // If date filter type is set to specific, prefill the date with the ones added in the active filters
      // Reason why its only for specific is because we dont know the date you want when its set to flexible, you will get timeslots instead
      if (activeFilters?.startTime && activeFilters.dateFilterType === 'SPECIFIC') {
        return {
          from: new Date(activeFilters.startTime),
          to: new Date(activeFilters.endTime!),
        };
      }

      return undefined;
    })();

    const bookingTimeInitial = (() => {
      // If vehicle category is present, it means timeslots will be used
      // So no need to prefill this since it depends on timeslots what the hours will be
      if (searchParams.get('vehicleCategoryId') && activeFilters?.dateFilterType !== 'SPECIFIC') {
        return undefined;
      }

      // Prefill startTime with the ones added in the active filters
      if (activeFilters?.startTime && activeFilters?.dateFilterType === 'SPECIFIC') {
        return {
          from: format(new Date(activeFilters.startTime), 'p', { locale }),
          to: format(new Date(activeFilters.endTime!), 'p', { locale }),
        };
      }

      // If no active filters are present, prefill with the ones from the booking settings if possible to fit within current time
      // Otherwise, prefill with my current time + buffer
      return {
        from: formatInitialTime({
          initialTime: bookingSettings?.value?.properties?.preselectedStartingTime,
          buffer: 60,
          roundTo: bookingSettings?.value?.properties?.timeGap,
          locale,
          timezone: branchData?.timeZoneId,
        }),
        to: formatInitialTime({
          initialTime: bookingSettings?.value?.properties?.preselectedEndingTime,
          buffer: 120,
          roundTo: bookingSettings?.value?.properties?.timeGap,
          locale,
          timezone: branchData?.timeZoneId,
        }),
      };
    })();

    return {
      bookingDate: bookingDateInitial,
      bookingTime: bookingTimeInitial,
      selectedMonth:
        activeFilters?.dateFilterType === 'FLEXIBLE'
          ? startOfMonth(activeFilters.startTime ? new Date(activeFilters.startTime) : new Date())
          : startOfMonth(bookingDateInitial?.from ?? new Date()),
      selectedVehicleCategory: searchParams.get('vehicleCategoryId')
        ? +searchParams.get('vehicleCategoryId')!
        : undefined,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingSettings, branchId, bookingData, activeFilters]);

  return {
    isFetchingBookingData:
      isFetchingBranchData || isFetchingBookingSettings || !!(branchId && isLoadingBookingData),
    bookingInitialValues: initialValues,
    isError: branchFetchingError || errorFetchingBookingSettings || errorFetchingBooking,
    bookingData,
    stationData: branchData,
  };
};
