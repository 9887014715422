import React, { FC } from 'react';
import { t } from '@lingui/core/macro';
import LocationSelect from '@/features/geomap/components/Form/LocationSelect';
import { useFormikContext } from 'formik';
import { BookingFiltersForm } from '@/features/booking/types';

interface LocationFilterT {
  isReadOnly?: boolean;
  onChange: () => void;
}

const LocationFilter: FC<LocationFilterT> = ({ isReadOnly, onChange }) => {
  const { setFieldValue } = useFormikContext<BookingFiltersForm>();

  return (
    <LocationSelect
      readonly={isReadOnly}
      fieldAttr={{
        placeholder: t({
          id: 'bookings.filters.pickUpDropOff',
          message: 'Pick up & drop off',
        }),
      }}
      fieldProps={{
        clearable: !isReadOnly,
        onClear: () => {
          void setFieldValue('boundingBoxCoordinates', undefined);
        },
        onChange: ({ additional }) => {
          const { boundingBoxCoordinates } = additional;
          void setFieldValue('boundingBoxCoordinates', boundingBoxCoordinates);
          if (onChange) {
            onChange();
          }
        },
        iconLeft: { icon: 'location', color: 'icon-400' },
        borderless: true,
      }}
      additionalMapping={{ boundingBoxCoordinates: 'bbox' }}
    />
  );
};

export default LocationFilter;
