import * as yup from 'yup';
import { t } from '@lingui/core/macro';
import { CommonValidationTranslations } from '@/core/utils/commonValidationTranslations';

export const passwordSchema = yup
  .string()
  .trim()
  .matches(/[a-z]/, () =>
    t({
      id: 'validations.password.lowerCaseRequirement',
      message: 'Password must have at least 1 lower case character',
    }),
  )
  .matches(/[A-Z]/, () =>
    t({
      id: 'validations.password.upperCaseRequirement',
      message: 'Password must have at least 1 upper case character',
    }),
  )
  .matches(/[0-9]/, () =>
    t({
      id: 'validations.password.numberRequirement',
      message: 'Password must have at least one number',
    }),
  )
  .required(() => CommonValidationTranslations.requiredField())
  .min(8, () =>
    t({
      id: 'validations.password.minLengthRequirement',
      message: 'Password must be at least 8 characters long',
    }),
  );
