import { useState } from 'react';
import { Row, Column, Button } from '@gourban/ui-components';
import styles from '@/features/account/assets/Onboarding/PaymentStep.module.scss';
import PackagePriceDetails from '@/features/account/components/Onboarding/PackagePriceDetails';
import cn from 'classnames';
import PaymentSources from '@/features/payment/components/PaymentSources';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSelectedCardId } from '@/features/account/redux/account.selectors';
import { Trans } from '@lingui/react/macro';
import { t } from '@lingui/core/macro';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';
import {
  useRequestPackageInformationQuery,
  useRequestPaymentSourcesQuery,
} from '@/features/payment/services/Payment.service';

const PaymentStep = () => {
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const { data: paymentSources } = useRequestPaymentSourcesQuery({ branchId: resolvedBranch!.id });
  const { data: packageInfo } = useRequestPackageInformationQuery(resolvedBranch!.id);
  const [displayProviderForm, setDisplayProviderForm] = useState(!paymentSources?.length);
  const userAddedPaymentSource = useTypedSelector(getSelectedCardId);

  const toggleProviderForm = () => setDisplayProviderForm(!displayProviderForm);

  return (
    <Row className={styles.payment} justify={!packageInfo ? 'center' : undefined}>
      {packageInfo && (
        <Column sm={12} md={6} xl={7} className={styles.payment__content}>
          <Row justify="center">
            <PackagePriceDetails packageInfo={packageInfo} />
          </Row>
        </Column>
      )}
      <Column
        sm={12}
        md={6}
        xl={5}
        className={cn([styles.payment__content, packageInfo && styles['payment--cards']])}
      >
        <Row
          alignItems={packageInfo ? 'flex-start' : 'center'}
          className={packageInfo ? styles['payment--cards__layout'] : undefined}
          column
        >
          <PaymentSources
            onPaymentMethodAdded={() => setDisplayProviderForm(false)}
            onAddPaymentMethod={toggleProviderForm}
            displayAddPaymentButton={!!userAddedPaymentSource}
            displayProviderForm={displayProviderForm}
            description={
              packageInfo
                ? t({
                    id: 'account.cards.subheading',
                    message: 'Complete your purchase by providing your payment details.',
                  })
                : t({
                    id: 'account.cards.subheadingNoPackage',
                    message: 'Provide your payment details.',
                  })
            }
          />
          {displayProviderForm && userAddedPaymentSource && (
            <Button
              className={styles['stripe--link']}
              variation="link"
              onClick={toggleProviderForm}
              iconName="arrowLeft"
              iconSize={{ width: 16, height: 16 }}
            >
              <Trans id="account.cards.useExistingCard">Use existing card</Trans>
            </Button>
          )}
        </Row>
      </Column>
    </Row>
  );
};

export default PaymentStep;
