import React, { FC } from 'react';
import { useLazyRequestLocationGeocodingQuery } from '@/features/geomap/services/Map.service';
import { AutocompleteSelect, FormSelectComponent, Autocomplete } from '@gourban/ui-components';
import { t } from '@lingui/core/macro';
import { useTypedSelector } from '@/core/redux/hooks';
import { getUserLocationCoordinates } from '@/features/account/redux/account.selectors';
import {
  mapSettingsDefaults,
  useRequestMapSettingsQuery,
} from '@/core/services/SettingsManagement.service';

interface LocationSelectT {
  name?: string;
  fieldProps?: Partial<FormSelectComponent<Autocomplete>['fieldProps']>;
  fieldAttr?: Partial<FormSelectComponent<Autocomplete>['fieldAttr']>;
  additionalMapping?: Record<string, string>;
  readonly?: boolean;
}

const LocationSelect: FC<LocationSelectT> = ({
  name = 'location',
  fieldProps = { label: t({ id: 'geomap.locationSelect.label', message: 'Location' }) },
  fieldAttr = { placeholder: t({ id: 'geomap.locationSelect.placeholder', message: 'Location' }) },
  additionalMapping = {},
  readonly,
}) => {
  const [requestLocations] = useLazyRequestLocationGeocodingQuery();
  const userCoordinates = useTypedSelector(getUserLocationCoordinates);
  const { data } = useRequestMapSettingsQuery();
  const mapSearchSettings = data?.value.mapSearchSettings ?? mapSettingsDefaults.mapSearchSettings;

  return (
    <AutocompleteSelect
      name={name}
      fieldAttr={{ id: 'location', ...fieldAttr, readonly }}
      fieldProps={{
        label: '',
        minSearchCharacters: 1,
        onChange: fieldProps?.onChange,
        options: {
          getData: (query) =>
            requestLocations({
              query,
              proximityCoordinates: userCoordinates,
              mapSearchSettings,
            }).unwrap(),
          mapData: {
            value: 'place_name',
            label: 'place_name',
            additional: additionalMapping,
          },
        },
        ...fieldProps,
      }}
    />
  );
};

export default LocationSelect;
