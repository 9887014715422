import React, { FC } from 'react';
import { Addition } from '@/features/vehicles/types';
import { Card, Column, Paragraph, Row } from '@gourban/ui-components';
import styles from '../../../../assets/scss/components/BookingFlow/AdditionCard.module.scss';
import { useFormikContext } from 'formik';
import { BookingOverviewForm } from '@/features/booking/types';
import cn from 'classnames';
import AdditionPriceBreakdown from './AdditionPriceBreakdown';
import CurrencyDisplay from '@/core/components/CurrencyDisplay';

interface AdditionCardT {
  addition: Addition;
  currency?: string;
  estimatedTotal: number;
}

const AdditionCard: FC<AdditionCardT> = ({ addition, currency, estimatedTotal }) => {
  const { setFieldValue, values } = useFormikContext<BookingOverviewForm>();

  const onAdditionSelection = () => {
    if (!values?.selectedAdditions) {
      void setFieldValue('selectedAdditions', [addition.code]);
      return;
    }

    if (values?.selectedAdditions?.includes(addition.code)) {
      void setFieldValue(
        'selectedAdditions',
        values.selectedAdditions.filter((additionCode) => additionCode !== addition.code),
      );

      return;
    }

    void setFieldValue('selectedAdditions', [...values.selectedAdditions, addition.code]);
  };

  const additionSelected = values?.selectedAdditions?.includes(addition.code);

  return (
    <Column sm={12}>
      <Card
        onClick={() => onAdditionSelection()}
        className={cn(styles.card, additionSelected && styles['card--active'])}
      >
        <Row marginBottom={8} justify="space-between">
          <Column>
            <Paragraph marginBottom={0} weight="regular">
              {addition.name}
            </Paragraph>
          </Column>
          <Column>
            <Paragraph className={styles.card__price} marginBottom={0} weight="regular">
              <AdditionPriceBreakdown addition={addition} currency={currency} />

              <CurrencyDisplay value={estimatedTotal} currency={currency!} />
              <span
                className={cn(
                  styles.card__checkbox,
                  additionSelected && styles['card__checkbox--checked'],
                )}
              />
            </Paragraph>
          </Column>
        </Row>
        {addition.description && (
          <Paragraph size={4} textColor="text-400" marginBottom={0}>
            {addition.description}
          </Paragraph>
        )}
      </Card>
    </Column>
  );
};

export default AdditionCard;
