import { useRequestSingleBranchQuery } from '@/features/branches/services/Branches.service';
import { getMyTimezoneId } from '@/core/utils/getMyTimezoneId';
import { formatInTimeZone } from 'date-fns-tz';
import { formatWithoutTZ } from '@/features/booking/utils/formatWithoutTZ';

export const useStationTimezone = (stationID: string, skip?: boolean) => {
  const { data: stationData, isFetching } = useRequestSingleBranchQuery(stationID, {
    skip,
  });

  const convertToStationTimezone = (date: Date) => {
    const { timeZoneId = getMyTimezoneId() } = stationData ?? {};

    return formatInTimeZone(formatWithoutTZ(date), timeZoneId, "yyyy-MM-dd'T'HH:mm:ssXXX");
  };

  return { convertToStationTimezone, stationData, isFetchingStation: isFetching };
};
