import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import styles from '@/core/assets/scss/components/UI/Image.module.scss';

interface ImageT {
  src?: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  fallback?: ReactNode;
}

const Image: FC<ImageT> = ({ src, alt, fallback, width, height }) => {
  return (
    <div
      style={{ width, height }}
      className={cn(styles.image, !src && styles['image--placeholder'])}
    >
      {src ? <img style={{ width, height }} src={src} alt={alt} /> : fallback}
    </div>
  );
};

export default Image;
