import React, { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { useTypedSelector } from '@/core/redux/hooks';
import { getIsLoggedIn } from '@/features/auth/redux/authorization.selectors';
import UnauthLayout from '@/core/components/Layouts/Unauth';

const RedirectIfAuth: FC = () => {
  const userAuthenticated = useTypedSelector(getIsLoggedIn);
  const location = useLocation();
  const onResetPasswordPage = location.pathname.startsWith('/auth/reset-password');
  const onLoginPage = location.pathname.startsWith('/auth/login'); // Login pages itself takes care of redirecting to the correct page itself so no need to this component to handle it

  if (userAuthenticated && !onLoginPage) {
    return <Navigate to="/" />;
  }

  return (
    <UnauthLayout hideHeader={onResetPasswordPage} hideFooter={onResetPasswordPage}>
      <Outlet />
    </UnauthLayout>
  );
};

export default RedirectIfAuth;
