import { RouteStaticConfig } from '@/core/types';
import { msg } from '@lingui/core/macro';

export const businessAccountRoot: RouteStaticConfig = {
  guarded: true,
  path: '/business-account',
  label: 'Business account',
};

export const businessAccountCreateRoot: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/create',
  label: 'Business account create',
};

export const businessAccountCreateCompanyInfoStep: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/create/company-info',
  label: msg({ id: 'businessAccount.create.companyInfo', message: 'Company info' }),
};

export const businessAccountCreatePaymentStep: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/create/payment',
  label: msg({ id: 'businessAccount.create.payment', message: 'Payment method' }),
};

export const businessAccountTrips: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/trips',
  additionalPaths: ['/business-account'],
  label: 'Business account trips',
};

export const businessAccountTrip: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/trips/:id',
  label: 'Business account trip',
};

export const businessAccountOnDemandTrips: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/on-demand-trips',
  label: 'On-demand Trips',
};

export const businessAccountOnDemandTrip: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/on-demand-trips/:id',
  label: 'On-demand trip',
};

export const businessAccountTripDrivingLog: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/trips/:id/:userGroupId/driving-log',
  label: 'Business account trip driving log',
};

export const businessAccountTeamRoot: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/team',
  label: 'Team',
};

export const businessAccountMembers: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/team/members',
  additionalPaths: ['/business-account/team'],
  label: msg({ id: 'general.mainMenu.members', message: 'Members' }),
  key: 'businessAccountTeamMembers',
};

export const businessAccountMembersInvite: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/team/members/invite',
  label: 'Invite member',
  key: 'businessAccountTeamMembersInvite',
};

export const businessAccountMember: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/team/members/:id',
  additionalPaths: ['/business-account/team/:id'],
  label: 'Member',
  key: 'businessAccountMemberView',
};

export const businessAccountCostCenters: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/cost-centers',
  label: msg({ id: 'general.mainMenu.costCenters', message: 'Cost centers' }),
  key: 'businessAccountTeamCostCenters',
};

export const businessAccountJoinRequests: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/team/join-requests',
  label: msg({ id: 'general.mainMenu.joinRequests', message: 'Join requests' }),
  key: 'businessAccountJoinRequests',
};

export const businessAccountCostCenterCreate: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/cost-centers/create',
  label: 'Cost centre create',
  key: 'businessAccountTeamCostCenterCreate',
};

export const businessAccountCostCentersView: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/cost-centers/:id',
  label: 'Cost centre view',
  key: 'businessAccountTeamCostCenterView',
};

export const businessAccountCompanyRoot: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/company',
  label: 'Company',
};

export const businessAccountBilling: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/company/billing',
  additionalPaths: ['/business-account/company'],
  label: msg({ id: 'general.mainMenu.billing', message: 'Billing' }),
  key: 'businessAccountCompanyBilling',
};

export const businessAccountSettings: RouteStaticConfig = {
  guarded: true,
  path: '/business-account/company/settings',
  label: msg({ id: 'general.mainMenu.settings', message: 'Settings' }),
  key: 'businessAccountCompanySettings',
};
