import { Outlet } from 'react-router';
import { useRequestMeDataQuery } from '@/features/account/services/Account.service';
import { Loader } from '@gourban/ui-components';
import useAccountSetupInit from '@/features/account/hooks/useAccountSetupInit';
import { useRequestBusinessAccountMembershipQuery } from '@/features/businessAccounts/services/BusinessAccount.service';
import { useRequestPackageInformationQuery } from '@/features/payment/services/Payment.service';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';
import ErrorFallback from '@/core/components/ErrorHandlers/ErrorFallback';
import { Trans } from '@lingui/react/macro';
import {
  useRequestBookingSettingQuery,
  useRequestGuardedSettingsBulkQuery,
} from '@/core/services/SettingsManagement.service';
import {
  useRequestWhitelabelAppQuery,
  useRequestWhitelabelQuery,
} from '@/core/services/WhiteLabeling.service';
import { useRequestVehicleAttributesQuery } from '@/features/vehicles/services/Vehicles.service';
import DownloadAppBanner from './DownloadAppBanner';

const AppSetupInit = () => {
  const { isLoading: isLoadingMeData, isError: meDataError } = useRequestMeDataQuery();
  const { isLoading: isLoadingBusinessAccountMembership, isError: businessAccountError } =
    useRequestBusinessAccountMembershipQuery();
  const {
    isLoading: isLoadingResolvedBranch,
    data: resolvedBranch,
    isError: branchError,
  } = useRequestResolvedBranchQuery();
  const { isLoading: isLoadingPackageInfo, isError: packageError } =
    useRequestPackageInformationQuery(resolvedBranch?.id!, {
      skip: !resolvedBranch,
    });
  const { isLoading: isLoadingVehicleAttributes } = useRequestVehicleAttributesQuery();
  const { isLoading: isLoadingGuardedSettings } = useRequestGuardedSettingsBulkQuery();
  const { isLoading: isLoadingBookingSettings } = useRequestBookingSettingQuery();
  const { isFetching: isFetchingWhiteLabeling } = useRequestWhitelabelQuery();
  const { isFetching: isFetchingWhiteLabelingApp } = useRequestWhitelabelAppQuery();

  useAccountSetupInit();

  // If any of these errors fail, we cant let the user go to the app
  if (meDataError || businessAccountError || branchError || packageError) {
    return (
      <ErrorFallback
        message={
          <Trans id="errorFallback.dataFailedToLoad">Data failed to load. Try again later.</Trans>
        }
      />
    );
  }

  if (
    isLoadingGuardedSettings ||
    isLoadingMeData ||
    isLoadingBusinessAccountMembership ||
    isLoadingResolvedBranch ||
    isLoadingPackageInfo ||
    isFetchingWhiteLabeling ||
    isLoadingBookingSettings ||
    isFetchingWhiteLabelingApp ||
    isLoadingVehicleAttributes
  ) {
    return <Loader fixed cover />;
  }

  return (
    <>
      <DownloadAppBanner />
      <Outlet />
    </>
  );
};

export default AppSetupInit;
